import React, { Fragment, useState, useEffect } from 'react';
// import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { Rings } from 'react-loader-spinner'

const Loader = () => {

    return (
        <Fragment>
            <div className='globalloader'>
                <div className='childglblloader'>
                    <Rings ariaLabel="loading-indicator" color='red' />
                </div>
            </div>
        </Fragment>
    );
};

export default Loader;