import React, { useEffect, useState, useRef } from 'react';
import { Container, Row, Col, Card, Form, Button, Table } from 'react-bootstrap';
import Breadcrumb from '../../components/common/breadcrumb';
import paginationFactory from 'react-bootstrap-table2-paginator';
import DataTables from '../commonComponent/DataTable/DataTables';
import SweetAlert from 'sweetalert2';
import { FrontDesk_URL, PineLabs_URL } from '../../constant';
import axios from 'axios';
import { Spinner } from 'react-bootstrap';
import ToolTip from '../commonComponent/ToolTip';
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';
import LoadingButton from '../commonComponent/LoadingButton';
import CommonToolTip from '../UI/ToolTips/CommonToolTip';
import NoRecordFounds from '../commonComponent/DataTable/NoRecordFounds';
import DeleteToolTip from '../UI/ToolTips/DeleteToolTip';

var fdate = "";
var fdate1 = "";
var typevalue = "1";
var glblArr = [];
var newpaymentArr = [];
var PendingAmountVar = "0";
var paymode = [];
var paymentModeIdSetting = "";
var glblCreditAuthorizer = [];
var glblDiscountAuthorizer = [];
var glblposmachine = [];
const RISOrders = () => {
    let visitNo = useRef();
    let mobileNo = useRef();
    let patientName = useRef();
    let sampleStatus = useRef();
    let sourceStatus = useRef();
    const [isLoading, setIsLoading] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [orderInfoModal, setOrderInfoModal] = useState();
    const [popupTableData, setPopupTableData] = useState([]);
    const [visitpopupdata, setVisitPopupData] = useState([]);
    const [selectedDate, setSelectedDate] = useState();
    const [selectedDate1, setSelectedDate1] = useState();
    const [outsourcecentre, setOutsourceCentre] = useState(false);
    const [typeSelected, setTypeSelected] = useState([]);
    const [typeSelectedName, setTypeSelectedName] = useState([]);
    const [outsourcecentrelist, setOutsourceCentreList] = useState([]);
    const [outsourcedoctorlist, setOutsourceDoctorList] = useState([]);
    const [visitid, setVisitId] = useState("0");
    const [doctorInfoModal, setDoctorInfoModal] = useState();
    const [rowdata, setRowData] = useState([]);
    const [typeSelectedDoctor, setTypeSelectedDoctor] = useState([]);
    const [typeSelectedDoctorName, setTypeSelectedDoctorName] = useState([]);
    const [isButtonLoading, setIsButtonLoading] = useState(false);
    const [crntPageNO, setCrntPageNO] = useState(0);
    const [channelid, setChannelId] = useState("0");
    const [hideshowpaymentbutton, setHideShowPaymentButton] = useState(false);
    const [Percentagecheckbox, setPercentagecheckbox] = useState("0");
    const [paymenttable, setPaymentTable] = useState([]);
    const [pendingamount, setPendingAmount] = useState("0");
    const [orderstatus, setOrderStatus] = useState("");
    const [orderid, setOrderId] = useState("0");
    const [PaymodeId, setPaymodeId] = useState("");
    const [VisitingId, setVisitingId] = useState("0");
    const [creditorderdetailid, setCreditOrderDetailId] = useState("0");
    const [totalamt, setTotalAmt] = useState(0);
    const [dueamt, setDueAmt] = useState(0);
    const [patientmobile, setPatientMobile] = useState("");
    const [patientname, setPatientName] = useState("");
    const [openmodal, setOpenModal] = useState(false);
    const [patientemail, setPatientEmail] = useState("");
    const [paymentModeId, setPaymentModeId] = useState("0");
    const [paymentmode, setPaymentMode] = useState([]);
    const [settleType, setSettleType] = useState("");
    const [discountAuthorizers, setDiscountAuthorizers] = useState([]);
    const [creditAuthorizers, setCreditAuthorizers] = useState([]);
    const [UserPOSCode, setUserPOSCode] = useState("");
    const [paymentChannels, setPaymentChannels] = useState([]);
    const [posmachineid, setPosMachineId] = useState("0");
    const [posmachines, setPOSMachines] = useState([]);
    const [isRazorLoading, setIsRazorLoading] = useState(false);
    const [creditauthorizerdetails, setCreditAuthorizerDetails] = useState({});
    const [glblrefno, setGlblRefNo] = useState("");
    const [posmachinename, setPosMachineName] = useState("");
    const [discountorderdetailid, setDiscountOrderDetailId] = useState("0");
    const [plutustransrefid, setPlutusTransRefId] = useState("0");
    const [transactionno, setTransactionNo] = useState("0");
    const [merchantstorepostcode, setMerchantStorePostCode] = useState("");
    const [imei, setIMEI] = useState("");
    const [discountauthorizerdetails, setDiscountAuthorizerDetails] = useState({});
    const [table, setTable] = useState([]);
    const [UserCustomerCode, setUserCustomerCode] = useState("");
    const [UserCashCode, setUserCashCode] = useState("");
    const [HideAmountInBookingSetting, setHideAmountInBookingSetting] = useState(false);
    const [PrintDepartmentSlipSetting, setPrintDepartmentSlipSetting] = useState(false);
    const [parentcentreid, setParentCentreId] = useState("0");
    const [loginuserid, setLoginUserId] = useState(localStorage.LoggedInUser == undefined || localStorage.LoggedInUser == null ? "" : localStorage.LoggedInUser);
    const [logincentreid, setLoginCentreId] = useState(localStorage.CentreSNo == undefined || localStorage.CentreSNo == null ? "" : localStorage.CentreSNo);
    const calendarRef = React.createRef();
    const calendarRef1 = React.createRef();
    let paymentamountdetail = useRef();
    let paymentmodedetail = useRef();
    let paymentChannelId = useRef();
    let paymentReferenceNo = useRef();
    let authorizerId = useRef();
    let concessionReason = useRef();
    let posMachineId = useRef();

    const openModal = () => { setOrderInfoModal(!orderInfoModal); };
    const openDoctorModal = () => { setIsButtonLoading(false); setDoctorInfoModal(!doctorInfoModal); };
    useEffect(() => {
        const today = new Date();
        const today1 = new Date();
        const date = today.setDate(today.getDate() - 3);
        const date1 = today1.setDate(today1.getDate());
        const defaultValue = new Date(date).toISOString().split('T')[0] // yyyy-mm-dd
        const defaultValueTo = new Date(date1).toISOString().split('T')[0] // yyyy-mm-dd


        var datenew = new Date(defaultValue),
            mnth = ("0" + (datenew.getMonth() + 1)).slice(-2),
            day = ("0" + datenew.getDate()).slice(-2);
        fdate = [datenew.getFullYear(), mnth, day].join("-");
        setSelectedDate(datenew);

        var datenew1 = new Date(defaultValueTo),
            mnth1 = ("0" + (datenew1.getMonth() + 1)).slice(-2),
            day1 = ("0" + datenew1.getDate()).slice(-2);
        fdate1 = [datenew1.getFullYear(), mnth1, day1].join("-");
        setSelectedDate1(datenew1);
        GetSettings(localStorage.CentreSNo, localStorage.LoggedInUser, defaultValue);
        BindGrid("", "", "", "0", "-1", fdate, fdate1);
        GetAuthorizer("2");
        GetPaymentChannels();
        GetPOSMachines();
    }, []);

    const idFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <span>{crntPageNO + rowIndex + 1}</span>
            </div>
        </React.Fragment>
    }
    const actionFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                {
                    rowData.IsCancelled == "1" || rowData.IsCancelled.toLowerCase() == "true" ? <span>Cancelled</span> :
                        <>
                            <ToolTip title="Order Info" placement="top" onClick={() => GetInfo(rowData)} linkId="Id" faName="fa fa-info-circle mr-2"></ToolTip>
                            {
                                (rowData.IStatus == "1" || rowData.IStatus.toLowerCase() == "true") ? <CommonToolTip title="Settle order" id={`SettleOrder${rowData.OrderId}`} placement="top" class="fa fa-rupee mr-2" onClick={() => openSettlePaymentModal(rowData, "Credit")} /> :
                                    null
                            }
                        </>
                }
                {/* <ToolTip title="Order Info" placement="top" onClick={() => GetInfo(rowData)} linkId="Id" faName="fa fa-info-circle mr-2"></ToolTip> */}
            </div>
        </React.Fragment>
    }
    const pendingFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                {
                    (rowData.PendingAmount == "0" || rowData.PendingAmount == "0.00" || rowData.PendingAmount.toLowerCase() == "false") ? "0.00" :
                        <span>{rowData.PendingAmount} <br /> Pending on: {rowData.CreditAuthorizer}</span>
                }
            </div>
        </React.Fragment>
    }
    const popupActionFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                {
                    (visitpopupdata.IsOutsource == "2" && parseInt(rowData.InvestigationStatus) == "-1") ?
                        <span><ToolTip title="Select doctor" placement="top" onClick={() => PushDoctorInfo(rowData)} linkId="Id" faName="fa fa-paper-plane mr-2"></ToolTip> Outstanding pending and order is outsourced to centre: <b>{rowData.OutsourceCentreName}</b></span> :
                        <>
                            {
                                 parseInt(rowData.InvestigationStatus) == "-1" ? <span><ToolTip title="Select doctor" placement="top" onClick={() => PushDoctorInfo(rowData)} linkId="Id" faName="fa fa-paper-plane mr-2"></ToolTip> Outstanding pending</span> :
                                    parseInt(rowData.InvestigationStatus) === 0 || parseInt(rowData.InvestigationStatus) === 6 ? <ToolTip title="Select doctor" placement="top" onClick={() => PushDoctorInfo(rowData)} linkId="Id" faName="fa fa-paper-plane mr-2"></ToolTip>
                                        : (parseInt(rowData.InvestigationStatus) > 0 && parseInt(rowData.InvestigationStatus) < 3) ? <span>Report pending</span>
                                            : parseInt(rowData.InvestigationStatus) === 3 ? <span>Report generated</span> : null
                            }
                        </>
                }
            </div>
        </React.Fragment>
    }
    const columns = [
        { dataField: '#', text: '#', editable: false, formatter: idFormatter, headerStyle: (colum, colIndex) => { return { width: '5%' }; } },
        { dataField: 'VisitingCode', text: 'Visit No.', editable: false },
        { dataField: 'PatientName', text: 'Name', editable: false },
        { dataField: 'Gender', text: 'Gender', editable: false },
        { dataField: 'MobileNo', text: 'Mobile No', editable: false },
        { dataField: 'OrderDate', text: 'Order Date', editable: false },
        { dataField: 'Status', text: 'Status', editable: false },
        { dataField: 'OutsourceFlag', text: 'Source Status', editable: false },
        { dataField: 'CreditAuthorizer', text: 'Pending On', editable: false },
        // { dataField: 'CreditAuthorizer', text: 'Pending On', editable: false },
        { dataField: '', text: 'Action', formatter: actionFormatter, editable: false, headerStyle: (colum, colIndex) => { return { width: '5%' }; } },
    ];
    const paginationOptions = {
        sizePerPage: 10,
        showTotal: false,
        firstPageText: 'First',
        prePageText: 'Back',
        nextPageText: 'Next',
        lastPageText: 'Last',
        onPageChange: (page, sizePerPage) => { setCrntPageNO(((page - 1) * sizePerPage)); }
    };
    const popupColumns = [
        { dataField: '#', text: '#', editable: false, formatter: idFormatter, headerStyle: (colum, colIndex) => { return { width: '7%' }; } },
        { dataField: 'OrderType', text: 'Investigation', editable: false },
        { dataField: 'OrderTypeCode', text: 'Test Code', editable: false, headerStyle: (colum, colIndex) => { return { width: '20%' }; } },
        { dataField: 'ModalityType', text: 'Modality', editable: false, headerStyle: (colum, colIndex) => { return { width: '15%' }; } },
        { dataField: '', text: 'Action', formatter: popupActionFormatter, editable: false, headerStyle: (colum, colIndex) => { return { width: '20%' }; } },
    ];

    const handleSearch = async () => { BindGrid(mobileNo.current.value, patientName.current.value, visitNo.current.value, sampleStatus.current.value, sourceStatus.current.value, fdate, fdate1); }
    const BindGrid = async (mobno, patname, vNo, samplestuts, sourcestatus, fDte, tDte) => {
        setIsLoading(true);
        const requestOptions = { MobileNo: mobno, PatientName: patname, VisitNo: vNo, Status: samplestuts, SourceStatus: sourcestatus, FromDate: fDte, ToDate: tDte, uId: localStorage.LoggedInUser, cId: localStorage.getItem('CentreSNo') };
        let url = FrontDesk_URL + 'ris/getorders'
        await axios.post(url, requestOptions).then((response) => {
            const data = response.data;
            if (data.Success) {
                const orderData = data.Data;
                console.log(orderData);
                if (orderData !== null) { setTableData(orderData); } else { setTableData([]); }
                setIsLoading(false);
            }
            else { SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime", icon: "error" }); setIsLoading(false); }
        }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } setIsLoading(false); });
    }
    const GetInfo = async (rowData) => { setTypeSelected([]); setTypeSelectedName([]); setOutsourceCentre(false); setVisitId(rowData.VisitingId); typevalue = "1"; setVisitPopupData(rowData); setPopupTableData(rowData.OrderItems); openModal(); }
    const PushDoctorInfo = async (rowData) => {
        if (visitpopupdata.IsOutsource == "2") {
            SweetAlert.fire({
                title: 'Order outsourced',
                text: "This order is already outsourced to centre :" + rowData.OutsourceCentreName,
                icon: 'error',
                showCancelButton: false,
                confirmButtonText: 'Ok',
                reverseButtons: true
            }).then((result) => { if (result.value) { } else { } })
            return false;
        }
        if (typevalue == "2") {
            SweetAlert.fire("This action is disabled for outsource order.");
            return false;
        }
        else {
            setTypeSelectedDoctor([]);
            setTypeSelectedDoctorName([]);
            GetOutsourceDoctor();
            setRowData(rowData);
            openDoctorModal();
        }
    }
    // const PushInfo = async (rowData) => {
    //     let url = FrontDesk_URL + 'ris/pushtoris/' + localStorage.getItem('LoggedInUser') + '/' + localStorage.getItem('CentreSNo') + '/' + rowData.VisitingId + '/' + rowData.Id
    //     await axios.get(url).then((response) => {
    //         const data = response.data;
    //         if (data.Success) { BindGrid(mobileNo.current.value, patientName.current.value, visitNo.current.value, sampleStatus.current.value, sourceStatus.current.value, fdate, fdate1); }
    //         else { SweetAlert.fire({ title: "Transaction Alert", text: data.ErrorList[0].errorMsg, icon: "error" }); }
    //     }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } });
    // }
    const GetOutsourceCentres = async () => {
        var glblArr1 = [];
        let url = FrontDesk_URL + 'ris/getoutsourcecentres/' + localStorage.getItem('LoggedInUser') + '/' + localStorage.getItem('CentreSNo')
        await axios.get(url).then((response) => {
            const data = response.data;
            if (data.Success) {
                if (data.Data == null || data.Data == undefined || data.Data == "") { SweetAlert.fire({ title: "Error", text: "No outsource centre found!", icon: "error" }); }
                else {
                    let ds = data.Data;
                    if (ds != null) {
                        ds.map((option) => { return glblArr1.push({ 'value': option.CentreId, 'label': option.CentreName, 'disabled': false }); });
                        setOutsourceCentreList(glblArr1);
                    }
                    else {
                        setOutsourceCentreList([]);
                    }
                }
            }
            else {
                if (data.Data == null || data.Data == undefined || data.Data == "") { SweetAlert.fire({ title: "Error", text: "Some error occured! Please try again", icon: "error" }); }
                else { SweetAlert.fire({ title: "Error", text: data.Data, icon: "error" }); }
            }
        }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } });
    }
    const handleDateChange = (date) => {
        var datenew = new Date(date),
            mnth = ("0" + (datenew.getMonth() + 1)).slice(-2),
            day = ("0" + datenew.getDate()).slice(-2);
        fdate = [datenew.getFullYear(), mnth, day].join("-");
        setSelectedDate(datenew);
    }
    const handleDateChange1 = (date) => {
        var datenew = new Date(date),
            mnth = ("0" + (datenew.getMonth() + 1)).slice(-2),
            day = ("0" + datenew.getDate()).slice(-2);
        fdate1 = [datenew.getFullYear(), mnth, day].join("-");
        setSelectedDate1(datenew);
    }
    const TypeChangeHandler = (options) => { setTypeSelected(options.value); setTypeSelectedName(options.label); }
    const TypeChangeHandlerDoctor = (options) => { setTypeSelectedDoctor(options.value); setTypeSelectedDoctorName(options.label); }
    const handleOutsourceCentre = async () => {
        if (typeSelected.length == 0) { SweetAlert.fire("Please select outsource centre"); return false; }
        else {
            SweetAlert.fire({
                title: 'Are you sure?',
                text: "You are going to make an order outsource to centre :" + typeSelectedName,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Ok',
                cancelButtonText: 'Cancel',
                reverseButtons: true
            }).then((result) => { if (result.value) { SetOutsource(); } else { SweetAlert.fire('Your order is still not outsourced!') } })
        }
    }
    const SetOutsource = async () => {
        if (visitid == "0") { SweetAlert.fire("Visit no not found.Please refresh and try again!"); return false; }
        const requestOptions = {
            VisitingId: visitid, OutsourceCentreId: typeSelected, CentreId: localStorage.getItem('CentreSNo'), LoginId: localStorage.getItem('LoggedInUser')
        };
        setIsButtonLoading(true);
        let url = FrontDesk_URL + 'ris/SetOutsource'
        await axios.post(url, requestOptions).then((response) => {
            const data = response.data;
            setIsButtonLoading(false);
            setIsLoading(false);
            if (data.Success) {
                SweetAlert.fire({
                    title: 'Outsourced',
                    text: "This order is outsourced now",
                    icon: 'success',
                    showCancelButton: false,
                    confirmButtonText: 'Ok',
                    reverseButtons: true
                }).then((result) => { if (result.value) { openModal(); window.location.reload(false); } else { openModal(); window.location.reload(false); } })
            }
            else {
                if (data.Data == null || data.Data == undefined || data.Data == "") { SweetAlert.fire({ title: "Error", text: "Some error occured! Please try again", icon: "error" }); }
                else { SweetAlert.fire({ title: "Error", text: data.Data, icon: "error" }); }
            }
        }).catch(function (error) { setIsButtonLoading(false); setIsLoading(false); if (error.response) { ErrorFunction(error.response.status); } });
    }
    const GetOutsourceDoctor = async () => {
        var glblArr2 = [];
        let url = FrontDesk_URL + 'ris/getoutsourcedoctor/' + localStorage.getItem('LoggedInUser') + '/' + localStorage.getItem('CentreSNo')
        await axios.get(url).then((response) => {
            const data = response.data;
            if (data.Success) {
                if (data.Data == null || data.Data == undefined || data.Data == "") { SweetAlert.fire({ title: "Error", text: "No outsource doctor found!", icon: "error" }); }
                else {
                    let ds = data.Data;
                    if (ds != null) {
                        ds.map((option) => { return glblArr2.push({ 'value': option.Id, 'label': option.Name, 'disabled': false }); });
                        setOutsourceDoctorList(glblArr2);
                    }
                    else {
                        setOutsourceDoctorList([]);
                    }
                }
            }
            else {
                if (data.Data == null || data.Data == undefined || data.Data == "") { SweetAlert.fire({ title: "Error", text: "Some error occured! Please try again", icon: "error" }); }
                else { SweetAlert.fire({ title: "Error", text: data.Data, icon: "error" }); }
            }
        }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } });
    }
    const saverisdata = async () => {
        if (typeSelectedDoctor.length == 0) { SweetAlert.fire("Please select doctor"); return false; }
        else {
            setIsButtonLoading(true);
            let url = FrontDesk_URL + 'ris/pushtoris/' + localStorage.getItem('LoggedInUser') + '/' + localStorage.getItem('CentreSNo') + '/' + rowdata.VisitingId + '/' + rowdata.Id + '/' + typeSelectedDoctor + '/' + typeSelectedDoctorName
            await axios.get(url).then((response) => {
                const data = response.data;
                setIsButtonLoading(false);
                // if (data.Success) { BindGrid(mobileNo.current.value, patientName.current.value, visitNo.current.value, sampleStatus.current.value, sourceStatus.current.value, fdate, fdate1); }
                if (data.Success) {
                    SweetAlert.fire({
                        title: 'Success',
                        text: "Item is successfully pushed to RIS",
                        icon: 'success',
                        showCancelButton: false,
                        confirmButtonText: 'Ok',
                        reverseButtons: true
                    }).then((result) => { if (result.value) { window.location.reload(false); } else { window.location.reload(false); } });
                    //window.location.reload(false); 
                }
                else {
                    if (data.ErrorList[0].errorCode == "RISResponse") { SweetAlert.fire({ title: "RIS Response", text: data.ErrorList[0].errorMsg, icon: "error" }); }
                    if (data.ErrorList[0].errorCode == "-25") { SweetAlert.fire({ title: "Request logging failed", text: data.ErrorList[0].errorMsg, icon: "error" }); }
                    else { SweetAlert.fire({ title: "Transaction Alert", text: data.ErrorList[0].errorMsg, icon: "error" }); }
                }
            }).catch(function (error) { if (error.response) { setIsButtonLoading(false); ErrorFunction(error.response.status); } });
        }
    }
    const handleTypeChange = async (e) => {
        setTypeSelected([]);
        setTypeSelectedName([]);
        setVisitId(visitpopupdata.VisitingId);
        if (e.target.value == "1") { setOutsourceCentre(false); }
        else { setOutsourceCentre((visitpopupdata.IsOutsource == "0" || visitpopupdata.IsOutsource.toLowerCase() == "false" || visitpopupdata.IsOutsource == "2") ? false : true); }
        if (e.target.value == "2" && (visitpopupdata.IsOutsource == "0" || visitpopupdata.IsOutsource.toLowerCase() == "false")) {
            SweetAlert.fire("Order cannot be outsourced because item was pushed to RIS");
            document.getElementById("invoiceType2").checked = false;
            document.getElementById("invoiceType1").checked = true;
            return false;
        }
        if (e.target.value == "2" && visitpopupdata.IsOutsource == "2") {
            SweetAlert.fire("Order cannot be outsourced because this centre already processed outsource");
            document.getElementById("invoiceType2").checked = false;
            document.getElementById("invoiceType1").checked = true;
            return false;
        }
        typevalue = e.target.value;
        GetOutsourceCentres();
    }

    ////////////////////////////////////////////////////////////////////////After popup implemented on this page////////////////////////////////////////////////////////////







    const openSettlePaymentModal = (rawData, callingType) => {
        setChannelId("0");
        setHideShowPaymentButton(false);
        setPercentagecheckbox("0");
        var tmpPendingAmount = rawData.PendingAmount;
        tmpPendingAmount = rawData.OutStandingAmount;
        setSettleType(callingType);
        glblArr = [];
        setPaymentTable([]);
        newpaymentArr = [];
        PendingAmountVar = tmpPendingAmount;
        setPendingAmount(tmpPendingAmount);
        setOrderStatus(rawData.OrderStatus);
        setOrderId(rawData.OrderId);
        setPaymodeId(rawData.PaymentModeId);
        setVisitingId(VisitingId);
        setCreditOrderDetailId(rawData.COrderDetailID);
        setTotalAmt(rawData.TotalAmount);
        setOpenModal(!openmodal);
        setPatientMobile(rawData.MobileNo);
        setPatientName(rawData.PatientName);
        setPatientEmail(rawData.PatientEmail);
        var tmpArray = [];
        tmpArray.push(<option value='1'>Cash</option>);
        tmpArray.push(<option value='13'>Payment Gateway</option>);
        tmpArray.push(<option value='15'>Outstanding</option>);
        setPaymentModeId("0");
        setPaymentMode([]);
        setPaymentMode(tmpArray);
        paymode = tmpArray;
        if (paymentamountdetail.current != null) {
            paymentamountdetail.current.value = tmpPendingAmount;
        }
    }
    const actionpaymentFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                {
                    channelid === "-1" || channelid == "8" ? null :
                        <DeleteToolTip title="Delete" placement="top" id={`DeletePayment${rowData.PaymentModeId == "Payment Gateway" ? "13" : rowData.PaymentModeId}`} onClick={() => RemovePayment(rowData.PaymentModeId, rowData.PaymentChannelId)} />
                }
            </div>
        </React.Fragment>
    }
    const paymentColumns = [
        { dataField: 'PaymentMode', text: 'Payment Mode', editable: false },
        { dataField: 'PaymentChannel', text: 'Payment Channel', editable: false },
        { dataField: 'PaidAmount', text: 'Paid Amount', editable: false },
        { dataField: 'ReferenceNo', text: 'Reference No.', editable: false },
        { dataField: 'Authorizer', text: 'Authorizer', editable: false },
        { dataField: 'POSMachineName', text: 'POS Machine', editable: false },
        { dataField: '', text: 'Remove', editable: false, formatter: actionpaymentFormatter }
    ];
    const GetAuthorizer = async (tId) => {
        try {
            let url = FrontDesk_URL + 'master/getauthorizers/' + localStorage.CentreSNo + '/' + tId
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) {
                    if (tId === "1") {
                        glblDiscountAuthorizer = [];
                        glblDiscountAuthorizer = data.Data;
                        setDiscountAuthorizers(glblDiscountAuthorizer.map((d) => (<option key={d.Id} value={d.Id}>{d.Name}</option>)));
                        setDiscountAuthorizerDetails({ "DiscountAuthorizerId": data.Data[0].Id, "DiscountAuthorizerName": data.Data[0].Name.split("[")[0], "DiscountAuthorizerEmail": data.Data[0].EmailID });
                    } else {
                        glblCreditAuthorizer = [];
                        glblCreditAuthorizer = data.Data;
                        setCreditAuthorizers(glblCreditAuthorizer.map((d) => (<option key={d.Id} value={d.Id}>{d.Name}</option>)));
                        setCreditAuthorizerDetails({ "CreditAuthorizerId": data.Data[0].Id, "CreditAuthorizerName": data.Data[0].Name.split("[")[0], "CreditAuthorizerEmail": data.Data[0].EmailID });
                    }
                }
            }).catch(function (error) { });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const GetPaymentChannels = async () => {
        try {
            let url = FrontDesk_URL + 'master/getpaymentchannels_phlebo/' + localStorage.LoggedInUser
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) { setPaymentChannels(data.Data.map((d) => (<option key={d.SNo} value={d.SNo}>{d.PaymentChannel}</option>))); }
            }).catch(function (error) { });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const GetPOSMachines = async () => {
        try {
            let url = FrontDesk_URL + 'master/getposmachines/' + localStorage.LoggedInUser
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) { glblposmachine = data.Data; setPOSMachines(data.Data.map((d) => (<option key={d.SNo} value={d.SNo}>{d.POSMachine}</option>))); }
            }).catch(function (error) { });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const RemovePayment = async (PaymentModeId, PaymentChannelId) => {
        newpaymentArr = [];
        SweetAlert.fire({ title: 'Are you sure?', text: "Once deleted, you will not be able to recover!", icon: 'warning', showCancelButton: true, confirmButtonText: 'Ok', cancelButtonText: 'Cancel', reverseButtons: true })
            .then((result) => {
                if (result.value) {
                    if (PaymentModeId === "13") {
                        glblArr = glblArr.filter(function (x) { return x.PaymentChannelId !== PaymentChannelId })
                    }
                    else {
                        glblArr = glblArr.filter(function (x) { return x.PaymentModeId !== PaymentModeId })
                    }
                    setPaymentTable(glblArr);
                    var totalPaidSoFar = 0;
                    for (var x in glblArr) { totalPaidSoFar = totalPaidSoFar + parseFloat(glblArr[x].PaidAmount); }
                    setPendingAmount(PendingAmountVar - totalPaidSoFar);
                    if (glblArr.length === 0) {
                        setPaymentMode(paymode.map((d) => (<option key={d.PaymentId} value={d.PaymentId}>{d.Payment}</option>)));
                    }
                    else {
                        for (var key in glblArr) {
                            newpaymentArr = paymode.filter((d) => d.PaymentId !== glblArr[key].PaymentModeId)
                        }
                        setPaymentMode(newpaymentArr.map((d) => (<option key={d.PaymentId} value={d.PaymentId}>{d.Payment}</option>)));
                    }
                    setPaymentModeId("0");
                    paymentmodedetail.current.value = "0";
                    if (paymentChannelId.current != undefined) { paymentChannelId.current.value = "0"; }
                    setChannelId("0");
                }
                else { SweetAlert.fire('Your data is safe!') }
            });
    }
    const ChangePaymentMode = (value) => {
        if (value === "Cash") { setPaymentModeId("1") }
        else if (value === "Discount" || value === "Discount After Bill") { setPaymentModeId("14") }
        else if (value === "Payment Gateway") { setPaymentModeId("13") }
        else if (value === "Outstanding") { setPaymentModeId("15") }
        else { setPaymentModeId(value); }
        if (value === "14" || value === "15") { }
        else { setPercentagecheckbox("0"); }
        paymentamountdetail.current.value = pendingamount;
    }
    const handlePercentageCheckbox = (e) => {
        const checkedflg = e.target.checked;
        if (checkedflg) { setPercentagecheckbox("1"); } else { setPercentagecheckbox("0"); }
    };
    const SelectChannel = async (e) => {
        setChannelId(e.target.value);
    }
    const SetAuthorizerDetails = (e) => {
        if (paymentModeId === "15") {
            for (var key in glblCreditAuthorizer) {
                if (glblCreditAuthorizer[key].Id === e.target.value) {
                    setCreditAuthorizerDetails({
                        "CreditAuthorizerName": glblCreditAuthorizer[key].Name.split("[")[0],
                        "CreditAuthorizerEmail": glblCreditAuthorizer[key].EmailID,
                        "CreditAuthorizerId": glblCreditAuthorizer[key].Id
                    });
                    return false;
                }
            }
        }
    }
    const CheckExistingRazorPayment = async () => {
        if (patientmobile == "" || patientmobile == null || patientmobile == undefined) {
            SweetAlert.fire("Patient mobile is mandatory");
            return false;
        }
        setIsRazorLoading(true);
        let url = FrontDesk_URL + 'accounts/checkexistingrazorpayment/' + patientmobile
        await axios.get(url).then((response) => {
            const data = response.data;
            setIsRazorLoading(false);
            if (data.Success) {
                if (data.Data == null) { SweetAlert.fire("No record found"); }
                else { setGlblRefNo(data.Data.ReferenceNo); CheckPaymentStatusRazorRecall(data.Data.ReferenceNo, data.Data.Amount); }
            }
            else {
                if (data.Data != null) { SweetAlert.fire("Error is: " + data.Data); }
                else { SweetAlert.fire("Some error occured.Check db logs"); }
            }
        }).catch(function (error) {
            setIsRazorLoading(false);
            if (error.response) { ErrorFunction(error.response.status); }
        });
    }
    const CheckPaymentStatusRazorRecall = async (refno, amount) => {
        if (refno == "") {
            SweetAlert.fire("Reference no is empty to check status.Please try again");
            return false;
        }
        setIsRazorLoading(true);
        let url = FrontDesk_URL + 'accounts/getphlebopaymentandupdatestatus/' + refno
        await axios.get(url).then((response) => {
            const data = response.data;
            setIsRazorLoading(false);
            if (data.Success) {
                if (data.Data == "-1001") { SweetAlert.fire("Currently don't have permission to access this feature. Please contact your admin."); }
                else {
                    if (data.Data == "-2") { SweetAlert.fire("Payment cancelled.Please try again."); }
                    else if (data.Data == "-3") { SweetAlert.fire("Payment expired.Please try again."); }
                    else if (data.Data == "0") { SweetAlert.fire("Payment still pending."); }
                    else if (data.Data == "-2000") { SweetAlert.fire("Some error occured.Check db logs"); }
                    else {
                        const referenceno = data.Data
                        paymentReferenceNo.current.value = referenceno;
                        RecallPaymentGridNew(referenceno, amount);
                    }
                }
            }
            else {
                SweetAlert.fire('Transaction failed while receiving response from razorpay.Please try again');
            }
        }).catch(function (error) {
            setIsRazorLoading(false);
            if (error.response) { ErrorFunction(error.response.status); }
        });
    }
    const RecallPaymentGridNew = async (posrefnumber, amount) => {
        var _authorizerId = ""; if (authorizerId.current !== null && authorizerId.current !== undefined) { _authorizerId = authorizerId.current.value; }
        var _authorizer = ""; if (authorizerId.current !== null && authorizerId.current !== undefined) { _authorizer = authorizerId.current.options[authorizerId.current.selectedIndex].text; }
        var _concessionReason = ""; if (concessionReason.current !== null && concessionReason.current !== undefined) { _concessionReason = concessionReason.current.value; }
        var txtPayMode = paymentmodedetail.current.options[paymentmodedetail.current.selectedIndex].text;
        var txtPayChannel = "";
        var pymntChnlId = "0";
        if (paymentChannelId.current != undefined) { pymntChnlId = paymentChannelId.current.value; txtPayChannel = paymentChannelId.current.options[paymentChannelId.current.selectedIndex].text; }
        if ((paymentmodedetail.current.value == "13" || paymentmodedetail.current.value == "Payment Gateway") && channelid === "-1" && posMachineId.current.value !== "0") {
            var POSMachineName = posMachineId.current.options[posMachineId.current.selectedIndex].text;
        }
        var posmchnId = "0";
        if (posMachineId.current != undefined) { posmchnId = posMachineId.current.value; }
        glblArr.push({
            OrderId: orderid, PaymentMode: txtPayMode, PaymentModeId: paymentmodedetail.current.value, PaymentChannelId: pymntChnlId,
            PaidAmount: amount, ReferenceNo: posrefnumber,
            Status: paymentmodedetail.current.options[paymentmodedetail.current.selectedIndex].text == "Discount After Bill" ? "0" : paymentmodedetail.current.value === "14" || paymentmodedetail.current.value.toLowerCase() === "discount" ? "6" : paymentmodedetail.current.value === "15" || paymentmodedetail.current.value.toLowerCase() === "credit" ? "0" : "1",
            OrderStatus: orderstatus, Authorizer: _authorizer, Reason: _concessionReason, POSMachineName: POSMachineName, POSMachineId: posmchnId,
            DiscountOrderDetailId: discountorderdetailid, CreditOrderDetailId: creditorderdetailid, PaymentChannel: txtPayChannel
        });
        setPaymentTable(glblArr);
        setPendingAmount(pendingamount - amount);
        if (paymentmodedetail.current.value == "13" || paymentmodedetail.current.value == "Payment Gateway") {
            if (channelid === "-1") {
                setPosMachineId(posMachineId.current.value);
                setPosMachineName(posMachineId.current.options[posMachineId.current.selectedIndex].text);
            }
        }
        paymentmodedetail.current.value = "0";
        paymentamountdetail.current.value = "";
        if (paymentModeId === "13" && channelid === "-1") { paymentChannelId.current.value = "0"; posMachineId.current.value = "0"; }
        else if (paymentModeId === "13" && channelid !== "-1") { paymentChannelId.current.value = "0"; paymentReferenceNo.current.value = ""; }
        setPaymentModeId("0");
        newpaymentArr = [];
        if (glblArr.length > 1) { setPaymentMode([]); }
        else {
            for (var key in glblArr) { newpaymentArr = paymode.filter((d) => d.PaymentId !== glblArr[key].PaymentModeId) }
            setPaymentMode(newpaymentArr.map((d) => (<option key={d.PaymentId} value={d.PaymentId}>{d.Payment}</option>)));
        }
        setChannelId("0");
    }
    const CheckPaymentStatusRazor = async () => {
        if (glblrefno == "") {
            SweetAlert.fire("Reference no is empty to check status.Please try again");
            return false;
        }
        setIsLoading(true);
        let url = FrontDesk_URL + 'accounts/getphlebopaymentandupdatestatus/' + glblrefno
        await axios.get(url).then((response) => {
            const data = response.data;
            setIsLoading(false);
            if (data.Success) {
                if (data.Data == "-1001") { setHideShowPaymentButton(false); SweetAlert.fire("Currently don't have permission to access this feature. Please contact your admin."); }
                else {
                    if (data.Data == "-2") { SweetAlert.fire("Payment cancelled.Please try again."); }
                    else if (data.Data == "-3") { SweetAlert.fire("Payment expired.Please try again."); }
                    else if (data.Data == "0") { SweetAlert.fire("Payment still pending."); }
                    else if (data.Data == "-2000") { SweetAlert.fire("Some error occured.Check db logs"); }
                    else {
                        setHideShowPaymentButton(false);
                        const referenceno = data.Data
                        paymentReferenceNo.current.value = referenceno;
                        RecallPaymentGrid(referenceno);
                    }
                }
            }
            else {
                SweetAlert.fire('Transaction failed while receiving response from razorpay.Please try again');
            }
        }).catch(function (error) {
            setIsLoading(false);
            if (error.response) { ErrorFunction(error.response.status); }
        });
    }
    const RecallPaymentGrid = async (posrefnumber) => {
        var _authorizerId = ""; if (authorizerId.current !== null && authorizerId.current !== undefined) { _authorizerId = authorizerId.current.value; }
        var _authorizer = ""; if (authorizerId.current !== null && authorizerId.current !== undefined) { _authorizer = authorizerId.current.options[authorizerId.current.selectedIndex].text; }
        var _concessionReason = ""; if (concessionReason.current !== null && concessionReason.current !== undefined) { _concessionReason = concessionReason.current.value; }
        var txtPayMode = paymentmodedetail.current.options[paymentmodedetail.current.selectedIndex].text;
        var txtPayChannel = "";
        var pymntChnlId = "0";
        if (paymentChannelId.current != undefined) { pymntChnlId = paymentChannelId.current.value; txtPayChannel = paymentChannelId.current.options[paymentChannelId.current.selectedIndex].text; }
        if ((paymentmodedetail.current.value == "13" || paymentmodedetail.current.value == "Payment Gateway") && channelid === "-1" && posMachineId.current.value !== "0") {
            var POSMachineName = posMachineId.current.options[posMachineId.current.selectedIndex].text;
        }
        var posmchnId = "0";
        if (posMachineId.current != undefined) { posmchnId = posMachineId.current.value; }
        glblArr.push({
            OrderId: orderid, PaymentMode: txtPayMode, PaymentModeId: paymentmodedetail.current.value, PaymentChannelId: pymntChnlId,
            PaidAmount: paymentamountdetail.current.value, ReferenceNo: posrefnumber,
            Status: paymentmodedetail.current.options[paymentmodedetail.current.selectedIndex].text == "Discount After Bill" ? "0" : paymentmodedetail.current.value === "14" || paymentmodedetail.current.value.toLowerCase() === "discount" ? "6" : paymentmodedetail.current.value === "15" || paymentmodedetail.current.value.toLowerCase() === "credit" ? "0" : "1",
            OrderStatus: orderstatus, Authorizer: _authorizer, Reason: _concessionReason, POSMachineName: POSMachineName, POSMachineId: posmchnId,
            DiscountOrderDetailId: discountorderdetailid, CreditOrderDetailId: creditorderdetailid, PaymentChannel: txtPayChannel
        });
        setPaymentTable(glblArr);
        setPendingAmount(pendingamount - paymentamountdetail.current.value);
        if (paymentmodedetail.current.value == "13" || paymentmodedetail.current.value == "Payment Gateway") {
            if (channelid === "-1") {
                setPosMachineId(posMachineId.current.value);
                setPosMachineName(posMachineId.current.options[posMachineId.current.selectedIndex].text);
            }
        }
        paymentmodedetail.current.value = "0";
        paymentamountdetail.current.value = "";
        if (paymentModeId === "13" && channelid === "-1") { paymentChannelId.current.value = "0"; posMachineId.current.value = "0"; }
        else if (paymentModeId === "13" && channelid !== "-1") { paymentChannelId.current.value = "0"; paymentReferenceNo.current.value = ""; }
        setPaymentModeId("0");
        newpaymentArr = [];
        if (glblArr.length > 1) { setPaymentMode([]); }
        else {
            for (var key in glblArr) { newpaymentArr = paymode.filter((d) => d.PaymentId !== glblArr[key].PaymentModeId) }
            setPaymentMode(newpaymentArr.map((d) => (<option key={d.PaymentId} value={d.PaymentId}>{d.Payment}</option>)));
        }
        setChannelId("0");
    }
    const CheckPaymentStatus = async () => {
        const requestOptions = {
            PlutusTransactionReferenceID: plutustransrefid, TransactionNumber: transactionno, MerchantStorePosCode: merchantstorepostcode, IMEI: imei
        };
        setIsLoading(true);
        let url = PineLabs_URL + 'PaymentPineLabs/TransactionStatus'
        await axios.post(url, requestOptions).then((response) => {
            const data = response.data;
            setIsLoading(false);
            if (data.Success) {
                if (data.Data === null) {
                    paymentmodedetail.current.value = "0";
                    setChannelId("0");
                    setPlutusTransRefId("0");
                    setTransactionNo("0");
                    setMerchantStorePostCode("");
                    setIMEI("");
                    setHideShowPaymentButton(false);
                    SweetAlert.fire('Runtime error occured, Please try again or contact support team.');
                }
                else {
                    if (data.Data.ResponseCode == "0" || data.Data.ResponseCode == "False") {
                        SweetAlert.fire({
                            title: 'Check POS Machine',
                            text: "Payment success from POS machine",
                            icon: 'success',
                            showCancelButton: false,
                            confirmButtonText: 'Ok',
                            reverseButtons: true
                        }).then((result) => {
                            if (result.value) {
                                setHideShowPaymentButton(false);
                                var isBreak = "0";
                                for (var key in data.Data.TransactionData) {
                                    if (data.Data.TransactionData[key].Tag == "TransactionLogId" && isBreak == "0") {
                                        isBreak = "1";
                                        RecallPaymentGrid(data.Data.TransactionData[key].Value);
                                    }
                                }
                            } else {
                                setHideShowPaymentButton(false);
                                var isBreak = "0";
                                for (var key in data.Data.TransactionData) {
                                    if (data.Data.TransactionData[key].Tag == "TransactionLogId" && isBreak == "0") {
                                        isBreak = "1";
                                        RecallPaymentGrid(data.Data.TransactionData[key].Value);
                                    }
                                }
                            }
                        })
                    }
                    else {
                        // setPlutusTransRefId("0");
                        // setTransactionNo("0");
                        // setMerchantStorePostCode("");
                        // setIMEI("");
                        SweetAlert.fire(data.Data.ResponseMessage);
                    }
                }
            }
            else {
                paymentmodedetail.current.value = "0";
                setChannelId("0");
                setPlutusTransRefId("0");
                setTransactionNo("0");
                setMerchantStorePostCode("");
                setIMEI("");
                setHideShowPaymentButton(false);
                SweetAlert.fire('Transaction failed while receiving response from POS.Please try again');
            }
        }).catch(function (error) {
            setIsLoading(false);
            if (error.response) { ErrorFunction(error.response.status); }
        });
    }
    const BindPaymentGrid = () => {
        if (paymentmodedetail.current.value == "0") { SweetAlert.fire('Please select payment mode'); return false }
        if (paymentamountdetail.current.value == "") { SweetAlert.fire('Please add payment amount'); return false }
        if (parseFloat(paymentamountdetail.current.value) <= 0) { SweetAlert.fire('Please add payment amount more than ZERO'); return false }
        if ((paymentmodedetail.current.value == "13" || paymentmodedetail.current.value == "Payment Gateway") && paymentChannelId.current.value === "0") { SweetAlert.fire('Please select payment channel'); return false }
        if ((paymentmodedetail.current.value == "13" || paymentmodedetail.current.value == "Payment Gateway") && paymentReferenceNo.current.value === "") {
            if (channelid == "-1" || channelid == "8") { }
            else { SweetAlert.fire('Please add reference no'); return false }
        }
        //if ((paymentmodedetail.current.value == "13" || paymentmodedetail.current.value == "Payment Gateway") && channelid !== "-1" && paymentReferenceNo.current.value === "") { SweetAlert.fire('Please add reference no'); return false }
        if ((paymentmodedetail.current.value == "13" || paymentmodedetail.current.value == "Payment Gateway") && channelid === "-1" && posMachineId.current.value === "0") { SweetAlert.fire('Please select POS machine'); return false }
        var payRefNo = ""; if (paymentReferenceNo.current !== null && paymentReferenceNo.current !== undefined) { payRefNo = paymentReferenceNo.current.value; }
        var _authorizerId = ""; if (authorizerId.current !== null && authorizerId.current !== undefined) { _authorizerId = authorizerId.current.value; }
        var _authorizer = ""; if (authorizerId.current !== null && authorizerId.current !== undefined) { _authorizer = authorizerId.current.options[authorizerId.current.selectedIndex].text; }
        var _concessionReason = ""; if (concessionReason.current !== null && concessionReason.current !== undefined) { _concessionReason = concessionReason.current.value; }
        if ((paymentmodedetail.current.value == "14" || paymentmodedetail.current.value === "Discount After Bill") && settleType.toLowerCase() === "credit") { //paymentmodedetail.current.value == "Discount"
            var selectedAuthorizorInfo = glblDiscountAuthorizer.filter(item => (item.Id === authorizerId.current.value));
            var orderPercent = parseFloat(selectedAuthorizorInfo[0].PerOrderLimit);
            var monthlyAvailableLimit = parseFloat(selectedAuthorizorInfo[0].MonthlyLimit);
            var curntAmount = paymentamountdetail.current.value;
            if (Percentagecheckbox === "1") {
                var crntDiscount = parseFloat(PendingAmountVar) * (parseFloat(paymentamountdetail.current.value) / 100);
                var discpercent = parseFloat(pendingamount) * 100 / (parseFloat(PendingAmountVar));
                if (parseFloat(crntDiscount) > parseFloat(monthlyAvailableLimit)) { SweetAlert.fire('Authorizer does not have sufficient limit to approve this amount.'); return false }
                if (parseFloat(curntAmount) > parseFloat(orderPercent)) { SweetAlert.fire('You can give ' + parseFloat(orderPercent).toFixed(2) + '% discount of pending amount value.'); return false }
                if (parseFloat(crntDiscount) > parseFloat(pendingamount)) { SweetAlert.fire('You can give discount of Rs.' + parseFloat(pendingamount).toFixed(2) + " or " + discpercent + "%  maximum"); return false }
            }
            else {
                var maxOrderAmount = parseFloat(PendingAmountVar) * (parseFloat(orderPercent) / 100);
                if (parseFloat(curntAmount) > parseFloat(maxOrderAmount)) { SweetAlert.fire('You can give ' + parseFloat(orderPercent).toFixed(2) + '% discount of pending amount value.'); return false }
                if (parseFloat(curntAmount) > parseFloat(monthlyAvailableLimit)) { SweetAlert.fire('Authorizer does not have sufficient limit to approve this amount.'); return false }
            }
        }
        // if (paymentmodedetail.current.value == "15" || paymentmodedetail.current.value == "Outstanding") {
        //     var selectedAuthorizorInfo = glblCreditAuthorizer.filter(item => (item.Id === authorizerId.current.value));
        //     var orderPercent = parseFloat(selectedAuthorizorInfo[0].PerOrderLimit);
        //     var monthlyAvailableLimit = parseFloat(selectedAuthorizorInfo[0].MonthlyLimit);
        //     var curntAmount = paymentamountdetail.current.value;
        //     if (Percentagecheckbox === "1") {
        //         var crntOutstanding = parseFloat(PendingAmountVar) * (parseFloat(paymentamountdetail.current.value) / 100);
        //         if (parseFloat(curntAmount) > parseFloat(orderPercent)) { SweetAlert.fire('You can give ' + parseFloat(orderPercent).toFixed(2) + '% outstanding of pending amount value.'); return false }
        //         if (parseFloat(crntOutstanding) > parseFloat(monthlyAvailableLimit)) { SweetAlert.fire('Authorizer does not have sufficient limit to approve this amount.'); return false }
        //         //if (parseFloat(crntOutstanding) > parseFloat(pendingamount)) { SweetAlert.fire('You can use outstanding amount of ' + parseFloat(pendingamount).toFixed(2) + '.'); return false }
        //     }
        //     else {
        //         var maxOrderAmount = parseFloat(PendingAmountVar) * (parseFloat(orderPercent) / 100);
        //         if (parseFloat(curntAmount) > parseFloat(maxOrderAmount)) { SweetAlert.fire('You can give ' + parseFloat(orderPercent).toFixed(2) + '% outstanding of pending amount value.'); return false }
        //         if (parseFloat(curntAmount) > parseFloat(monthlyAvailableLimit)) { SweetAlert.fire('Authorizer does not have sufficient limit to approve this amount.'); return false }
        //     }
        // }
        var pChkbx = "";
        if (Percentagecheckbox === "0") { if (parseFloat(pendingamount) < parseFloat(paymentamountdetail.current.value)) { SweetAlert.fire('Amount cannot be greater than pending amount'); return false } }
        if (Percentagecheckbox === "1") { pChkbx = parseFloat(parseFloat(PendingAmountVar) * (parseFloat(paymentamountdetail.current.value) / 100)).toFixed(2); }
        else { pChkbx = paymentamountdetail.current.value; }
        var txtPayMode = paymentmodedetail.current.options[paymentmodedetail.current.selectedIndex].text;
        var txtPayChannel = "";
        var pymntChnlId = "0";
        if (paymentChannelId.current != undefined) { pymntChnlId = paymentChannelId.current.value; txtPayChannel = paymentChannelId.current.options[paymentChannelId.current.selectedIndex].text; }
        if ((paymentmodedetail.current.value == "13" || paymentmodedetail.current.value == "Payment Gateway") && channelid === "-1" && posMachineId.current.value !== "0") {
            var POSMachineName = posMachineId.current.options[posMachineId.current.selectedIndex].text;
        }
        var posmchnId = "0";
        if (posMachineId.current != undefined) { posmchnId = posMachineId.current.value; }
        /////////////This logic is to stop adding same payment mode again, and this logic replaces below commented logic/////////////////
        if (glblArr.length > 0) {
            var payvalue = paymentmodedetail.current.value == "Cash" ? "1" : (paymentmodedetail.current.value == "Discount" || paymentmodedetail.current.value === "Discount After Bill") ? "14" : paymentmodedetail.current.value === "Payment Gateway" ? "13" : paymentmodedetail.current.value
            var isValueBreak = 0;
            for (var key in glblArr) {
                if (glblArr[key].PaymentModeId !== "13" && isValueBreak === 0) {
                    if (glblArr[key].PaymentModeId === payvalue && isValueBreak === 0) {
                        isValueBreak = 1
                    }
                }
                if (glblArr[key].PaymentModeId === "13" && isValueBreak === 0) {
                    if (paymentChannelId.current != undefined) {
                        if (glblArr[key].PaymentChannelId === paymentChannelId.current.value && isValueBreak === 0) {
                            isValueBreak = 2
                        }
                    }
                }
            }
            if (isValueBreak === 1) {
                SweetAlert.fire("Payment mode already selected, please remove selected mode or change it.");
                return false;
            }
            if (isValueBreak === 2) {
                SweetAlert.fire("Payment channel already selected, please remove selected channel or change it.");
                return false;
            }
        }
        /////////////This logic is to stop adding same payment mode again, and this logic replaces below commented logic/////////////////
        /////////////This logic is build to make 100 % credit and discount amount transfer to another user instead of multiple settlement/////////////////
        if (Percentagecheckbox === "0") {
            if (settleType == "Discount") { if (parseFloat(PendingAmountVar) != parseFloat(paymentamountdetail.current.value) && (paymentmodedetail.current.value == "14" || paymentmodedetail.current.value == "Discount")) { SweetAlert.fire('Please enter total discount amount. You cannot make multiple settlements with discount amount'); return false } }
            if (parseFloat(PendingAmountVar) != parseFloat(paymentamountdetail.current.value) && (paymentmodedetail.current.value == "15" || paymentmodedetail.current.value == "Outstanding")) { SweetAlert.fire('Please enter total outstanding amount. You cannot make multiple settlements with outstanding amount'); return false }
        }
        else {
            var maxOrderAmount = parseFloat(PendingAmountVar) * (parseFloat(paymentamountdetail.current.value) / 100);
            if (settleType == "Discount") { if (parseFloat(PendingAmountVar) != parseFloat(maxOrderAmount) && (paymentmodedetail.current.value == "14" || paymentmodedetail.current.value == "Discount")) { SweetAlert.fire('Please enter total discount amount. You cannot make multiple settlements with discount amount'); return false } }
            if (parseFloat(PendingAmountVar) != parseFloat(maxOrderAmount) && (paymentmodedetail.current.value == "15" || paymentmodedetail.current.value == "Outstanding")) { SweetAlert.fire('Please enter total outstanding amount. You cannot make multiple settlements with outstanding amount'); return false }
        }
        /////////////This logic is build to make 100 % credit and discount amount transfer to another user instead of multiple settlement/////////////////
        if (channelid === "-1") {
            generatepinelabtransactionid(pChkbx.toString());
        }
        else if ((paymentmodedetail.current.value == "13" || paymentmodedetail.current.value == "Payment Gateway") && channelid == "8") {
            GetReferenceLink();
        }
        else {
            glblArr.push({
                OrderId: orderid, PaymentMode: txtPayMode, PaymentModeId: paymentmodedetail.current.value == "Cash" ? "1" : (paymentmodedetail.current.value == "Discount" || paymentmodedetail.current.value == "Discount After Bill") ? "14" : paymentmodedetail.current.value === "Payment Gateway" ? "13" : paymentmodedetail.current.value, PaymentChannelId: pymntChnlId,
                PaidAmount: pChkbx.toString(), ReferenceNo: payRefNo,
                Status: paymentmodedetail.current.options[paymentmodedetail.current.selectedIndex].text == "Discount After Bill" ? "0" : paymentmodedetail.current.value === "14" || paymentmodedetail.current.value.toLowerCase() === "discount" ? "6" : paymentmodedetail.current.value === "15" || paymentmodedetail.current.value.toLowerCase() === "credit" ? "0" : "1",
                OrderStatus: orderstatus, Authorizer: _authorizer, Reason: _concessionReason, POSMachineName: POSMachineName, POSMachineId: posmchnId,
                DiscountOrderDetailId: discountorderdetailid, CreditOrderDetailId: creditorderdetailid, PaymentChannel: txtPayChannel
            });
            setPaymentTable(glblArr);
            setPendingAmount(pendingamount - pChkbx.toString());
            if (paymentmodedetail.current.value == "13" || paymentmodedetail.current.value == "Payment Gateway") {
                if (channelid === "-1") {
                    setPosMachineId(posMachineId.current.value);
                    setPosMachineName(posMachineId.current.options[posMachineId.current.selectedIndex].text);
                }
            }
            paymentmodedetail.current.value = "0";
            paymentamountdetail.current.value = "";
            if (paymentModeId === "13" && channelid === "-1") { paymentChannelId.current.value = "0"; posMachineId.current.value = "0"; }
            else if (paymentModeId === "13" && channelid !== "-1") { paymentChannelId.current.value = "0"; paymentReferenceNo.current.value = ""; }
            setPaymentModeId("0");
            setChannelId("0");
        }
    }
    const generatepinelabtransactionid = async (amountvalue) => {
        const requestOptions = {
            MobileNo: patientmobile, CentreID: localStorage.CentreSNo, Amount: amountvalue, AddedBy: localStorage.LoggedInUser
        };
        setIsLoading(true);
        let url = PineLabs_URL + 'PaymentPineLabs/SetPineLabsTransaction'
        await axios.post(url, requestOptions).then((response) => {
            const data = response.data;
            setIsLoading(false);
            if (data.Success) {
                var transactionnumber = data.Data;
                uploadtransaction((amountvalue * 100), transactionnumber);
            }
            else { SweetAlert.fire('TransactionId not created, Please try again later.'); }
        }).catch(function (error) {
            setIsLoading(false);
            if (error.response) { ErrorFunction(error.response.status); }
        });
    }
    const uploadtransaction = async (amountvalue, transactionnumber) => {
        var arr = [];
        arr = glblposmachine.filter((d) => d.SNo === posMachineId.current.value);
        const requestOptions = {
            TransactionNumber: transactionnumber, SequenceNumber: "1", AllowedPaymentMode: "0", Amount: amountvalue, AutoCancelDurationInMinutes: "5", UserID: localStorage.LoggedInUser,
            MerchantStorePosCode: arr[0].MerchantStorePosCode, IMEI: arr[0].IMEI
        };
        setIsLoading(true);
        let url = PineLabs_URL + 'PaymentPineLabs/uploadtransaction'
        await axios.post(url, requestOptions).then((response) => {
            const data = response.data;
            setIsLoading(false);
            if (data.Success) {
                if (data.Data === null) {
                    setPlutusTransRefId("0");
                    setTransactionNo("0");
                    setMerchantStorePostCode("");
                    setIMEI("");
                    setHideShowPaymentButton(false);
                    SweetAlert.fire('Runtime error occured, Please try again or contact support team.');
                }
                else {
                    if (data.Data.ResponseCode == "0" || data.Data.ResponseCode == "False") {
                        setPlutusTransRefId(data.Data.PlutusTransactionReferenceID);
                        setTransactionNo(transactionnumber);
                        setMerchantStorePostCode(arr[0].MerchantStorePosCode);
                        setIMEI(arr[0].IMEI);
                        SweetAlert.fire({
                            title: 'Check POS Machine',
                            text: "Payment sent to POS machine",
                            icon: 'success',
                            showCancelButton: false,
                            confirmButtonText: 'Ok',
                            reverseButtons: true
                        }).then((result) => { if (result.value) { setHideShowPaymentButton(true); } else { setHideShowPaymentButton(true); } })

                    }
                    else {
                        setPlutusTransRefId("0");
                        setTransactionNo("0");
                        setMerchantStorePostCode("");
                        setIMEI("");
                        setHideShowPaymentButton(false);
                        SweetAlert.fire(data.Data.ResponseMessage);
                    }
                }
            }
            else {
                setPlutusTransRefId("0");
                setTransactionNo("0");
                setMerchantStorePostCode("");
                setIMEI("");
                setHideShowPaymentButton(false);
                SweetAlert.fire('Transaction failed while pushing to POS.Please try again');
            }
        }).catch(function (error) {
            setIsLoading(false);
            if (error.response) { ErrorFunction(error.response.status); }
        });
    }
    const GetReferenceLink = async () => {
        var grandtotalamount = paymentamountdetail.current.value;
        var unixtimstamp = Math.floor(new Date().getTime() * 1000);
        var description = "0";
        var centreusername = (patientname == "" || patientname == null || patientname == undefined) ? "0" : patientname;
        var mobileno = (patientmobile == "" || patientmobile == null || patientmobile == undefined) ? "0" : patientmobile;
        var email = (patientemail == "" || patientemail == null || patientemail == undefined) ? "0" : patientemail;
        setIsButtonLoading(true);
        try {
            let url = FrontDesk_URL + 'accounts/getphlebopaymenturl/' + grandtotalamount.toString() + '/' + unixtimstamp.toString() + '/' + description + '/' + centreusername + '/' + mobileno + '/' + email + '/' + logincentreid + '/' + loginuserid
            await axios.get(url).then((response) => {
                const data = response.data;
                setIsButtonLoading(false);
                if (data.Success) {
                    if (data.Data == null) { SweetAlert.fire("Some error occured! Please try again after sometime"); }
                    else {
                        if (data.Data == "-1001") {
                            SweetAlert.fire("Currently don't have permission to access this feature. Please contact your admin.");
                        }
                        else {
                            CallExternalPaymentLink(data.Data.ReferenceId, data.Data.PaymentKey, grandtotalamount, unixtimstamp, description, centreusername, mobileno, email);
                        }
                    }
                }
                else {
                    setIsButtonLoading(false);
                    if (data.Data == "0") { SweetAlert.fire("Payment link already sent to user.Click razorpay pending status button"); }
                    else { SweetAlert.fire("Error Code: " + data.Data + " Check db logs!"); }
                }
            }).catch(function (error) { setIsButtonLoading(false); if (error.response) { ErrorFunction(error.response.status); } });
        } catch (error) { setIsButtonLoading(false); SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const CallExternalPaymentLink = async (refId, paymentKey, amount, expiry, description, centreusername, mobileno, email) => {
        amount = parseFloat(amount) * 100;
        try {
            let url = FrontDesk_URL + 'accounts/callexternalpaymentlinkphlebo/' + refId + '/' + amount.toString() + '/' + expiry.toString() + '/' + description + '/' + (centreusername == "" ? "0" : centreusername) + '/' + (mobileno == "" ? "0" : mobileno) + '/' + (email == "" ? "0" : email)
            await axios.get(url).then((response) => {
                const data = response.data;
                setIsButtonLoading(false);
                if (data.Success) {
                    if (data.Data == "-1001") { setHideShowPaymentButton(false); SweetAlert.fire("Currently don't have permission to access this feature. Please contact your admin."); }
                    else {
                        setGlblRefNo(refId);
                        setHideShowPaymentButton(true);
                        SweetAlert.fire("Payment link sent to patient mobile number");
                        //window.open(data.Data, "_blank");
                    }
                }
                else {
                    setGlblRefNo("");
                    setIsButtonLoading(false);
                    setHideShowPaymentButton(false);
                    if (data.Data == "-2") { SweetAlert.fire("No payment link generated.Please try again"); }
                    else { SweetAlert.fire("Error : " + data.Data + " Check logs!"); }
                }
            }).catch(function (error) { setHideShowPaymentButton(false); setIsButtonLoading(false); if (error.response) { ErrorFunction(error.response.status); } });
        } catch (error) { setHideShowPaymentButton(false); setIsButtonLoading(false); SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const SavePaymentDetails = async () => {
        if (pendingamount != 0) {
            SweetAlert.fire("Please add pending amount");
            return false;
        }
        if (UserCustomerCode == "" || UserCustomerCode == null || UserCustomerCode == undefined) {
            SweetAlert.fire("You are not authorized because your sap code not found");
            return false;
        }
        if (UserCashCode == "" | UserCashCode == null || UserCashCode == undefined) {
            SweetAlert.fire("You are not authorized because your cash code not found");
            return false;
        }
        var PName = "";
        var Age = "";
        var GName = "";
        var PUHID = "";
        var BCentre = "";
        var UName = "";
        var PAmount = "";
        var NAmount = "";
        var NAmount = "";
        var VDate = "";
        var discountauthid = "";
        var creditauthid = "";
        var discountauthname = "";
        var discountauthemail = "";
        var creditauthname = "";
        var creditauthemail = "";
        var modeisavail = "0";
        var concessionReason = "";
        for (var key in paymenttable) {
            if (paymenttable[key].PaymentModeId === "14" || paymenttable[key].PaymentModeId.toLowerCase() === "discount" || paymenttable[key].PaymentModeId === "Discount After Bill") {
                modeisavail = "1";
                discountauthname = discountauthorizerdetails.DiscountAuthorizerName === undefined ? "" : discountauthorizerdetails.DiscountAuthorizerName
                discountauthemail = discountauthorizerdetails.DiscountAuthorizerEmail === undefined ? "" : discountauthorizerdetails.DiscountAuthorizerEmail
                discountauthid = discountauthorizerdetails.DiscountAuthorizerId
                concessionReason = paymenttable[key].Reason
            }
            if (paymenttable[key].PaymentModeId === "15" || paymenttable[key].PaymentModeId.toLowerCase() === "credit") {
                creditauthname = creditauthorizerdetails.CreditAuthorizerName === undefined ? "" : creditauthorizerdetails.CreditAuthorizerName
                creditauthemail = creditauthorizerdetails.CreditAuthorizerEmail === undefined ? "" : creditauthorizerdetails.CreditAuthorizerEmail
                creditauthid = creditauthorizerdetails.CreditAuthorizerId
                concessionReason = paymenttable[key].Reason
            }
            for (var keyy in table) {
                if (paymenttable[key].OrderId === table[keyy].OrderId) {
                    PName = table[keyy].PName;
                    Age = table[keyy].Age;
                    GName = table[keyy].GName;
                    PUHID = table[keyy].PUHID;
                    BCentre = table[keyy].BCentre;
                    UName = table[keyy].UName;
                    PAmount = table[keyy].PaidAmount;
                    NAmount = table[keyy].TotalAmount;
                    VDate = table[keyy].VisitDate;
                }
            }
        }
        var alldataArr = [];
        var pmodeid = "0";
        var pmodeamount = "";
        for (var key in paymenttable) {
            var paymodeid = (paymenttable[key].PaymentModeId === "Discount" || paymenttable[key].PaymentModeId === "Discount After Bill") ? "14" : paymenttable[key].PaymentModeId === "Cash" ? "1" : paymenttable[key].PaymentModeId === "Payment Gateway" ? "13" : paymenttable[key].PaymentModeId === "Credit" ? "15" : paymenttable[key].PaymentModeId
            var paymodeamount = (paymenttable[key].PaymentModeId === "Discount" || paymenttable[key].PaymentModeId === "Discount After Bill" || paymenttable[key].PaymentModeId === "14" || paymenttable[key].PaymentModeId === "15" || paymenttable[key].PaymentModeId === "Credit") ? paymenttable[key].PaidAmount : ""
            alldataArr.push({
                OrderId: paymenttable[key].OrderId,
                PaymentModeId: paymodeid,
                PaymentChannelId: paymenttable[key].PaymentChannelId,
                PaidAmount: paymenttable[key].PaidAmount,
                ReferenceNo: paymenttable[key].ReferenceNo,
                Status: paymenttable[key].Status,
                OrderStatus: paymenttable[key].OrderStatus,
                DiscountOrderDetailId: (settleType == "Discount" || settleType == "Discount After Bill") ? paymenttable[key].DiscountOrderDetailId : "0",
                CreditOrderDetailId: (settleType == "Credit") ? paymenttable[key].CreditOrderDetailId : "0",
            })
            if (paymodeid == "14" || paymodeid == "15") { pmodeid = paymodeid; pmodeamount = paymodeamount; }
        }
        var requestOptions = {
            CentreId: localStorage.CentreSNo,
            LoginId: localStorage.LoggedInUser,
            DiscountAuthorizerName: discountauthname,
            DiscountAuthorizerEmail: discountauthemail,
            CreditAuthorizerName: creditauthname,
            CreditAuthorizerEmail: creditauthemail,
            VisitingId: VisitingId,
            modeisavail: modeisavail,
            DiscountAuthorizerId: (settleType == "Discount" || pmodeid == "14") ? discountauthid : creditauthid,
            SettleType: (settleType == "Discount" || settleType == "Discount After Bill") ? "14" : "15",
            ConcessionReason: concessionReason,
            PName: PName,
            Age: Age,
            GName: GName,
            PUHID: PUHID,
            BCentre: BCentre,
            UName: UName,
            PAmount: PAmount,
            NAmount: NAmount,
            VDate: VDate,
            POSMachineId: posmachineid,
            POSMachineName: posmachinename,
            AllDataArray: JSON.stringify(alldataArr),
            PModeId: pmodeid,
            PModeAmount: pmodeamount,
            RefId: glblrefno,
            IsOutsourceCentre: "1"
        };
        setIsButtonLoading(true);
        try {
            let url = FrontDesk_URL + 'master/savepaymentdetails'
            await axios.post(url, requestOptions).then((response) => {
                const data = response.data;
                setIsButtonLoading(false);
                if ((paymenttable.length - 1).toString() === key.toString()) {
                    if (data.Success) {
                        SweetAlert.fire({ title: "Success", text: "Data saved successfully", icon: "success" });
                        setOpenModal(!openmodal);
                        BindGrid("", "", "", "0", "-1", fdate, fdate1);
                    }
                    else {
                        if (data.Data == "-1") {
                            SweetAlert.fire('You had already requested for discount.');
                            return false;
                        }
                        else if (data.Data == "-9990") {
                            SweetAlert.fire('You are not authorized because your sap code not found.');
                            return false;
                        }
                        else if (data.Data == "-9991") {
                            SweetAlert.fire('You are not authorized because your cash code not found.');
                            return false;
                        }
                        else if (data.Data == "-9999") {
                            SweetAlert.fire('Outstanding already settled');
                            setOpenModal(!openmodal);
                            BindGrid("", "", "", "0", "-1", fdate, fdate1);
                            return false;
                        }
                        else if (data.Data == "-9998") {
                            SweetAlert.fire('Discount already settled');
                            setOpenModal(!openmodal);
                            BindGrid("", "", "", "0", "-1", fdate, fdate1);
                            return false;
                        }
                        else if (data.Data == "-9997") {
                            SweetAlert.fire('Discount already approved');
                            setOpenModal(!openmodal);
                            BindGrid("", "", "", "0", "-1", fdate, fdate1);
                            return false;
                        }
                        else if (data.Data == "-9996") {
                            SweetAlert.fire('Outstanding already approved');
                            setOpenModal(!openmodal);
                            BindGrid("", "", "", "0", "-1", fdate, fdate1);
                            return false;
                        }
                        else { SweetAlert.fire({ title: "Error", text: "Unable to save data.Please try again", icon: "error" }); }
                    }
                }
            }).catch(function (error) {
                setIsButtonLoading(false);
                if (error.response) {
                    ErrorFunction(error.response.status);
                }
            });
        }
        catch (error) {
            setIsButtonLoading(false);
            SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" });
        }
    }
    const GetSettings = async (cId, uId, defaultValue) => {
        //setIsLoading(true);
        try {
            let url = FrontDesk_URL + 'master/getcentresettings/' + cId + '/' + uId;
            await axios.get(url).then((response) => {
                const data = response.data;
                //setIsLoading(false);
                if (data.Success) {
                    const settingInfo = data.Data;
                    let hideBookingAmount = false; if (settingInfo.HideBookingAmount === "True") { hideBookingAmount = true; }
                    let printDepartmentSlip = false; if (settingInfo.PrintDepartmentSlip === "True") { printDepartmentSlip = true; }
                    setHideAmountInBookingSetting(hideBookingAmount);
                    setPrintDepartmentSlipSetting(printDepartmentSlip);
                    setParentCentreId(settingInfo.ParentCentreId);
                    setUserPOSCode(settingInfo.UserPOSCode);
                    setUserCustomerCode(settingInfo.UserCustomerCode);
                    setUserCashCode(settingInfo.UserCashCode);
                    paymentModeIdSetting = settingInfo.PaymentModeId;
                    localStorage.setItem('MinBookingCash', settingInfo.MinBookingCash);
                }
            }).catch(function (error) { //setIsLoading(false); 

            });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); setIsLoading(false); }
    }
    const ErrorFunction = async (ErrorCode) => {
        if (ErrorCode == "400")
            SweetAlert.fire({ title: "Error 400", text: "Bad request", icon: "error" })
        else if (ErrorCode == "401")
            SweetAlert.fire({ title: "Error 401", text: "Unauthorized", icon: "error" })
        else if (ErrorCode == "403")
            SweetAlert.fire({ title: "Error 403", text: "Forbidden", icon: "error" })
        else if (ErrorCode == "404")
            SweetAlert.fire({ title: "Error 404", text: "Page not found", icon: "error" })
        else if (ErrorCode == "500")
            SweetAlert.fire({ title: "Error 500", text: "Internal Server Error", icon: "error" })
        else if (ErrorCode == "503")
            SweetAlert.fire({ title: "Error 503", text: "Service Unavailable", icon: "error" })
    }
    return (
        <>
            <Breadcrumb title="Orders" parent="RIS" />
            <Container fluid>
                <Row>
                    <Col md={12}>
                        <Card>
                            <Card.Body>
                                <Form>
                                    <Form.Row>
                                        <Col md={3}>
                                            <Form.Group>
                                                <Form.Label>Mobile No.:</Form.Label>
                                                <Form.Control type='text' ref={mobileNo} />
                                            </Form.Group>
                                        </Col>
                                        <Col md={3}>
                                            <Form.Group>
                                                <Form.Label>Name:</Form.Label>
                                                <Form.Control type='text' ref={patientName} />
                                            </Form.Group>
                                        </Col>
                                        <Col md={3}>
                                            <Form.Group>
                                                <Form.Label>Visit No.:</Form.Label>
                                                <Form.Control type='text' ref={visitNo} />
                                            </Form.Group>
                                        </Col>
                                        <Col md={3}>
                                            <Form.Group>
                                                <Form.Label>Status</Form.Label>
                                                <Form.Control as="select" id="sample" name="sample" ref={sampleStatus}>
                                                    <option value="-1" selected>All</option>
                                                    <option selected={true} value="0">Pending</option>
                                                    <option value="1">Pushed to RIS (Partial)</option>
                                                    <option value="2">Pushed to RIS</option>
                                                    <option value="3">Report Generated</option>
                                                </Form.Control>
                                            </Form.Group>
                                        </Col>
                                        <Col md={3}>
                                            <Form.Group>
                                                <Form.Label>Source Status</Form.Label>
                                                <Form.Control as="select" id="source" name="source" ref={sourceStatus}>
                                                    <option value="-1" selected>All</option>
                                                    <option value="0">Insource</option>
                                                    <option value="1">Outsource Out</option>
                                                    <option value="2">Outsource In</option>
                                                </Form.Control>
                                            </Form.Group>
                                        </Col>
                                        <Col md={3}>
                                            <Form.Group>
                                                <Form.Label>Date From</Form.Label>
                                                {/* <Form.Control type="date" id="date1" name="date1" ref={fromDate} /> */}
                                                <DatePicker selected={selectedDate} showMonthDropdown={true} showYearDropdown={true} dateFormat="yyyy-MM-dd" className='form-control'
                                                    onChange={handleDateChange} ref={calendarRef}
                                                />
                                                <span className='calandericon1' onClick={() => { calendarRef.current.setOpen(true) }}><i className="fa fa-calendar" aria-hidden="true"></i></span>
                                            </Form.Group>
                                        </Col>
                                        <Col md={3}>
                                            <Form.Group>
                                                <Form.Label>Date To</Form.Label>
                                                {/* <Form.Control type="date" id="date2" name="date2" ref={toDate} /> */}
                                                <DatePicker selected={selectedDate1} showMonthDropdown={true} showYearDropdown={true} dateFormat="yyyy-MM-dd" className='form-control'
                                                    onChange={handleDateChange1} ref={calendarRef1}
                                                />
                                                <span className='calandericon1' onClick={() => { calendarRef1.current.setOpen(true) }}><i className="fa fa-calendar" aria-hidden="true"></i></span>
                                            </Form.Group>
                                        </Col>
                                    </Form.Row>
                                    <Form.Row className='mt15'>
                                        <Col md={12} className='text-center'>
                                            <Button variant='primary' onClick={handleSearch} >Search</Button>
                                        </Col>
                                    </Form.Row>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <Card>
                            <Card.Header className='pb-0'>
                                <Card.Title>Orders List</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                {
                                    isLoading ?
                                        <div className='text-center'> <Spinner animation="border" size='sm' /></div> :
                                        <>
                                            <DataTables keyField='id' tableData={tableData} columns={columns} pagination={paginationFactory(paginationOptions)} />
                                        </>
                                }
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                {orderInfoModal &&
                    <Modal isOpen={orderInfoModal} toggle={openModal} centered={true} size="lg">
                        <ModalHeader toggle={openModal}>Tests List</ModalHeader>
                        <ModalBody>
                            <Row>
                                <Col md={12}>
                                    <Card>
                                        <Card.Body>
                                            <Form.Group className='animate-chk m-checkbox-inline mt-15'>
                                                <Form.Label htmlFor="invoiceType1">
                                                    <input class="radio_animated" type="radio" id='invoiceType1' name="rdo-inv" defaultChecked={true} value='1' onChange={(e) => handleTypeChange(e)} />Doctor
                                                </Form.Label>
                                                <Form.Label htmlFor="invoiceType2">
                                                    <input class="radio_animated" type="radio" id='invoiceType2' name="rdo-inv" value='2' onChange={(e) => handleTypeChange(e)} />Outsource
                                                </Form.Label>
                                            </Form.Group>
                                            <DataTables keyField='PlabId' tableData={popupTableData} columns={popupColumns} pagination={paginationFactory(paginationOptions)} />
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                {
                                    outsourcecentre == true ?
                                        <>
                                            <Col md={12}>
                                                <Form.Group>
                                                    <Form.Label>Outsource Centre</Form.Label>
                                                    <Select onChange={TypeChangeHandler}
                                                        options={outsourcecentrelist}
                                                        value={outsourcecentrelist.filter(function (x) { return x.value === typeSelected; })} />
                                                </Form.Group>
                                            </Col>
                                            <Col md={12} className='text-center'>
                                                {isButtonLoading ? <LoadingButton variantName='primary' cssName="" /> :
                                                    <Button variant='primary' onClick={handleOutsourceCentre} >Send to outsource centre</Button>}
                                            </Col>
                                        </>
                                        : null
                                }
                            </Row>
                        </ModalBody>
                    </Modal>
                }
                {doctorInfoModal &&
                    <Modal isOpen={doctorInfoModal} toggle={openDoctorModal} centered={true} size="lg">
                        <ModalHeader toggle={openDoctorModal}>Doctor List</ModalHeader>
                        <ModalBody>
                            <Row>
                                <Col md={12}>
                                    <Card>
                                        <Card.Body>
                                            <Col md={12}>
                                                <Form.Group>
                                                    <Form.Label>Doctor</Form.Label>
                                                    <Select onChange={TypeChangeHandlerDoctor}
                                                        options={outsourcedoctorlist}
                                                        value={outsourcedoctorlist.filter(function (x) { return x.value === typeSelectedDoctor; })} />
                                                </Form.Group>
                                            </Col>
                                            <Col md={12} className='text-center'>
                                                {isButtonLoading ? <LoadingButton variantName='primary' cssName="" /> :
                                                    <Button variant='primary' type='submit' onClick={saverisdata} >Save & push to RIS</Button>}
                                            </Col>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </ModalBody>
                    </Modal>
                }
            </Container>
            {
                openmodal &&
                <Modal isOpen={openmodal} toggle={openSettlePaymentModal} centered={true} size="lg">
                    <ModalHeader toggle={openSettlePaymentModal}>Settle Payment</ModalHeader>
                    <ModalBody>
                        <Form.Row>
                            <Col md={3}>
                                <Form.Group>
                                    <Form.Label>Payment Mode</Form.Label>
                                    <Form.Control as="select" ref={paymentmodedetail} onChange={(e) => { ChangePaymentMode(e.target.value) }} >
                                        <option key="0" value="0">----Select-----</option>
                                        {paymentmode}
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                            {
                                paymentModeId === "14" && settleType.toLowerCase() === "credit" && paymentModeIdSetting !== "2" ?
                                    <Col md={3}>
                                        <Form.Group style={{ marginTop: 35, marginLeft: 20 }}>
                                            <input className="checkbox_animated" id="inpercentage" type="checkbox" onClick={(e) => { handlePercentageCheckbox(e); }} /> In Percentage
                                        </Form.Group>
                                    </Col> :
                                    null
                            }
                            {Percentagecheckbox === "0" ?
                                <>
                                    {
                                        paymentModeId === "14" && settleType.toLowerCase() === "discount" ?
                                            <Col md={3}>
                                                <Form.Group>
                                                    <Form.Label>Amount</Form.Label>
                                                    <Form.Control type="text" ref={paymentamountdetail} disabled />
                                                </Form.Group>
                                            </Col> :
                                            paymentModeId === "15" && settleType.toLowerCase() === "credit" ?
                                                <Col md={3}>
                                                    <Form.Group>
                                                        <Form.Label>Amount</Form.Label>
                                                        <Form.Control type="text" ref={paymentamountdetail} disabled />
                                                    </Form.Group>
                                                </Col> :
                                                <Col md={3}>
                                                    <Form.Group>
                                                        <Form.Label>Amount</Form.Label>
                                                        <Form.Control type="text" ref={paymentamountdetail} />
                                                    </Form.Group>
                                                </Col>
                                    }
                                </>
                                :
                                <Col md={3}>
                                    <Form.Group>
                                        <Form.Label>In Percentage Amount</Form.Label>
                                        <Form.Control type="text" ref={paymentamountdetail} />
                                    </Form.Group>
                                </Col>
                            }
                            {paymentModeId === "13" ?
                                <>
                                    <Col md={3}>
                                        <Form.Group>
                                            <Form.Label>Payment Channel</Form.Label>
                                            <Form.Control as="select" ref={paymentChannelId} onChange={(e) => SelectChannel(e)}>
                                                {<option value="0">----Select-----</option>}
                                                {UserPOSCode === "" ? null : <option value="-1">POS</option>}
                                                {paymentChannels}
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                    {
                                        channelid === "0" ? null :
                                            <>
                                                {
                                                    channelid === "-1" || channelid == "8" ?
                                                        <Col md={3}>
                                                            <Form.Group>
                                                                <Form.Label>Reference No. <span className='text-red'>(Max 50 characters)</span></Form.Label>
                                                                <Form.Control type="text" maxLength={50} ref={paymentReferenceNo} disabled />
                                                            </Form.Group>
                                                        </Col> :
                                                        <Col md={3}>
                                                            <Form.Group>
                                                                <Form.Label>Reference No. <span className='text-red'>(Max 50 characters)</span></Form.Label>
                                                                <Form.Control type="text" maxLength={50} ref={paymentReferenceNo} />
                                                            </Form.Group>
                                                        </Col>
                                                }
                                            </>
                                    }
                                </>
                                : paymentModeId === "14" || paymentModeId === "15" && paymentModeIdSetting !== "2" ?
                                    <>
                                        <Col md={3}>
                                            <Form.Group>
                                                <Form.Label>{paymentModeId === "14" ? "Discount Authorizer" : "Credit Authorizer"}</Form.Label>
                                                <Form.Control as="select" ref={authorizerId} onChange={(e) => SetAuthorizerDetails(e)}>
                                                    {paymentModeId === "14" ? discountAuthorizers : creditAuthorizers}
                                                </Form.Control>
                                            </Form.Group>
                                        </Col>
                                        <Col md={3}>
                                            <Form.Group>
                                                <Form.Label>{paymentModeId === "14" ? "Discount" : "Credit"} Reason <span className='text-red'>(Max 100 characters allowed)</span></Form.Label>
                                                <Form.Control type="text" ref={concessionReason} maxLength={100} />
                                            </Form.Group>
                                        </Col>
                                    </>
                                    : null}
                            {
                                paymentModeId === "13" && channelid === "-1" ?
                                    <Col md={2}>
                                        <Form.Group>
                                            <Form.Label>POS Machine</Form.Label>
                                            <Form.Control as="select" ref={posMachineId} >
                                                {<option value="0">----Select-----</option>}
                                                {posmachines}
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                    : null
                            }
                            {
                                hideshowpaymentbutton === false ?
                                    <>
                                        <Col md={2} xs={11}>
                                            <Form.Group className='m-t-15'>
                                                <Form.Label className='icRight'>
                                                    <Button variant='secondary' onClick={BindPaymentGrid}>Add</Button>
                                                </Form.Label>
                                            </Form.Group>
                                        </Col>
                                        {
                                            channelid == "8" ?
                                                <Col md={2} xs={11}>
                                                    <Form.Group className='m-t-15'>
                                                        <Form.Label className='icRight'>
                                                            {isRazorLoading ? <LoadingButton variantName='secondary' cssName="" /> :
                                                                <Button variant='primary' onClick={CheckExistingRazorPayment}>Check razorpay pending status</Button>}
                                                        </Form.Label>
                                                    </Form.Group>
                                                </Col> : null
                                        }
                                    </>
                                    :
                                    channelid == "8" ?
                                        <Col md={2} xs={11}>
                                            <Form.Group className='m-t-15'>
                                                <Form.Label className='icRight'>
                                                    {isLoading ? <LoadingButton variantName='secondary' cssName="" /> :
                                                        <Button variant='secondary' onClick={CheckPaymentStatusRazor}>Check payment status</Button>}
                                                </Form.Label>
                                            </Form.Group>
                                        </Col> :
                                        <Col md={2} xs={11}>
                                            <Form.Group className='m-t-15'>
                                                <Form.Label className='icRight'>
                                                    {isLoading ? <LoadingButton variantName='secondary' cssName="" /> :
                                                        <Button variant='secondary' onClick={CheckPaymentStatus}>Check payment status</Button>}
                                                </Form.Label>
                                            </Form.Group>
                                        </Col>
                            }
                        </Form.Row>
                        <div className='text-right'>
                            <b>Pending Amount:</b> <span className='text-danger mr-2'>{pendingamount}</span>
                        </div>
                        <div className="table-responsive mt15">
                            <DataTables keyField='Id' tableData={paymenttable} columns={paymentColumns} noDataIndication={NoRecordFounds} />
                        </div>
                        {
                            paymenttable.length > 0 ?
                                <>
                                    <Row>
                                        <Col md={12} className='text-center'>
                                            {isButtonLoading ? <LoadingButton variantName='primary' cssName="" /> : <Button variant='primary' onClick={SavePaymentDetails}>Save</Button>}
                                        </Col>
                                    </Row>
                                </>
                                :
                                null
                        }
                    </ModalBody>
                </Modal>
            }
        </>
    )
}

export default RISOrders