import React, { useEffect, useState, useRef } from 'react';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import axios from 'axios';
import SweetAlert from 'sweetalert2';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Spinner } from 'react-bootstrap';
import ToolTip from '../commonComponent/ToolTip';
import { FrontDesk_URL, CallCentre_URL } from '../../constant';
import DataTables from '../commonComponent/DataTable/DataTables';
import Breadcrumb from '../../components/common/breadcrumb';
import { useHistory } from 'react-router';
import { ProgressBar, Step } from "react-step-progress-bar";
import LoadingButton from '../commonComponent/LoadingButton';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Alert from "react-bootstrap/Alert";

var glblsteps = [];
var glbltransfer = [];
const ReportOverview = () => {
    const history = useHistory();
    const [pendingPathologyData, setPendingPathologyData] = useState([]);
    const [pathologyTableData, setPathologyTableData] = useState([]);
    const [radiologyTableData, setRadiologyTableData] = useState([]);
    const [crntPageNO, setCrntPageNO] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [orderDate, setOrderDate] = useState("");
    const [allowPrint, setAllowPrint] = useState("");
    const [patientName, setPatientName] = useState("");
    const [currentStatus, setCurrentStatus] = useState("0");
    const [visitingCode, setVisitingCode] = useState("");
    const [pathlabReportUrl, setPathlabReportUrl] = useState("");
    const [pathlabReportUrlWithH, setPathlabReportUrlWithH] = useState("");
    const [isButtonLoading, setIsButtonLoading] = useState(false);
    const [modal, setModal] = useState(false);
    const [steps, setSteps] = useState([]);
    const [modaldata, setModalData] = useState([]);
    const [transfer, setTransfer] = useState({});
    const [editmobilenumber, setEditMobileNumber] = useState(false);
    const [sendotp, setSendOTP] = useState(false);
    const [saveotp, setSaveOTP] = useState("");
    const [mobno, setMobNo] = useState("");
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);
    let editmobno = useRef();
    let verifyotp = useRef();
    useEffect(() => { handleShow(); }, [currentStatus])
    useEffect(() => {
        BindGrid();
        return () => {
            localStorage.removeItem('mode');
        }
    }, [])
    useEffect(() => {
        if (modaldata.length > 0) { modalHandler(); }
    }, [modaldata])

    useEffect(() => {
        const interval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }

            if (seconds === 0) {
                if (minutes === 0) {
                    clearInterval(interval);
                } else {
                    setSeconds(30);
                    setMinutes(minutes - 1);
                }
            }
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    });


    const paginationOptions = {
        sizePerPage: 10, showTotal: false, firstPageText: 'First', prePageText: 'Back', nextPageText: 'Next', lastPageText: 'Last',
        onPageChange: (page, sizePerPage) => { setCrntPageNO(((page - 1) * sizePerPage)); }
    };
    const idFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <span>{crntPageNO + rowIndex + 1}</span>
            </div>
        </React.Fragment>
    }
    const rdActionFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            {
                (rowData.SampleCollectionStatus === "Report Generated" && (allowPrint === "True" || allowPrint === "1")) ?
                    <div>
                        {
                            localStorage.PartnerTypeId === "3" || localStorage.PartnerTypeId === "4" ?
                                <ToolTip title="Download Report" placement="top" onClick={() => openReport(rowData.ReportUrlName)} linkId="ReportLink" faName="fa fa-download mr-2"></ToolTip> :
                                <>
                                    <ToolTip title="Download Report" placement="top" onClick={() => openReport(rowData.ReportUrlName)} linkId="ReportLink" faName="fa fa-download mr-2"></ToolTip>
                                    {/* <ToolTip title="Send Email" placement="top" linkId="Email" faName="fa fa-envelope mr-2"></ToolTip>
                                    <ToolTip title="Send whatsapp" placement="top" linkId="whatsapp" faName="fa fa-whatsapp mr-2"></ToolTip> */}
                                    <ToolTip title="Send Report" placement="top" linkId="Report" faName="fa fa-send-o mr-2" onClick={() => SendReport("2", rowData.Id)}></ToolTip>
                                </>
                        }
                    </div>
                    :
                    (rowData.SampleCollectionStatus === "Report Generated" && (allowPrint === "False" || allowPrint === "0")) ?
                        <div><span>You do not have sufficient credit limit to download report</span></div>
                        :
                        (rowData.SampleCollectionStatus === "Report Generated" && allowPrint === "2") ?
                            <div><span>You won't able to view report because your discount settlement is pending</span></div>
                            :
                            (rowData.SampleCollectionStatus === "Report Generated" && allowPrint === "3") ?
                                <div><span>You won't able to view report because your credit settlement is pending</span></div> :
                                <div><span>Not Generated</span></div>
            }
        </React.Fragment>
    }
    const pathActionFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            {
                <div>
                    {
                        rowData.SampleCollectionStatus == "ordered" ? <span>Ordered</span> :
                            rowData.SampleCollectionStatus == "processed" ? <span>Collected</span> :
                                rowData.SampleCollectionStatus == "submitted" ? <span>Submitted</span> :
                                    rowData.SampleCollectionStatus == "sample collected" ? <span>Sample Collected</span> :
                                        rowData.SampleCollectionStatus == "sample department received" ? <span>Sample Department Received</span> :
                                            rowData.SampleCollectionStatus == "rejected" ? <span>Rejected</span> :
                                                rowData.SampleCollectionStatus == "report generated" ? <span>Report Generated</span> :
                                                    <span>{rowData.SampleCollectionStatus}</span>
                    }
                </div>
            }
        </React.Fragment>
    }
    const rdColumns = [
        { dataField: '#', text: '#', editable: false, formatter: idFormatter, headerStyle: (colum, colIndex) => { return { width: '5%' }; } },
        { dataField: 'Item', text: 'Investigation', editable: false },
        { dataField: 'ItemCode', text: 'Code', editable: false },
        { dataField: 'AddedOn', text: 'Order Date', editable: false },
        { dataField: 'actions', text: 'Actions', formatter: rdActionFormatter, editable: false }
    ];
    const pathColumns = [
        { dataField: '#', text: '#', editable: false, formatter: idFormatter, headerStyle: (colum, colIndex) => { return { width: '5%' }; } },
        { dataField: 'Item', text: 'Investigation', editable: false },
        { dataField: 'ItemCode', text: 'Code', editable: false },
        { dataField: 'AddedOn', text: 'Order Date', editable: false },
        { dataField: 'SampleCollectionStatus', text: 'Status', editable: false, formatter: pathActionFormatter },
        // { dataField: 'actions', text: 'Actions', formatter: pathActionFormatter, editable: false, headerStyle: (colum, colIndex) => { return { width: '7%' }; } }
    ];
    const openReport = async (fileUrl) => { window.open(fileUrl, "_blank"); }
    const BindGrid = async () => {
        setIsLoading(true);
        let url = FrontDesk_URL + 'labreport/getreportoverview/' + localStorage.getItem('CentreSNo') + '/' + localStorage.getItem('LoggedInUser') + '/' + localStorage.getItem('vId')
        await axios.get(url).then((response) => {
            const data = response.data;
            if (data.Success) {
                if (data.Data != null) {
                    var apiData = data.Data;
                    setOrderDate(apiData.AddedOn);
                    setAllowPrint(apiData.AllowPrint);
                    setPatientName(apiData.PatientName);
                    setCurrentStatus(apiData.Status);
                    setVisitingCode(apiData.VisitingCode);
                    var reportResults = apiData.ReportResults;

                    setPendingPathologyData(reportResults.filter((d) => d.CategoryName === "Pathology" && d.SampleCollectionStatus !== "Report Generated"));
                    var tmpArray = reportResults.filter((d) => d.CategoryName === "Pathology" && d.SampleCollectionStatus === "Report Generated");
                    if (tmpArray.length > 0) { setPathlabReportUrl(tmpArray[0].ReportUrlName); setPathlabReportUrlWithH(tmpArray[0].ReportURLWithHeader); }
                    setPathologyTableData(tmpArray);
                    setRadiologyTableData(reportResults.filter((d) => d.CategoryName === "Radiology"));

                    // data.Data.orderdashboard.filter((d) => d.VisitingId !== visitingid)
                }
                setIsLoading(false);
            }
            else { APIError(data.Data, data.ErrorList.length === 0 ? "" : data.ErrorList[0].errorMsg); setIsLoading(false); }
        }).catch(function (error) { if (error.response) { AxiosError(error.response.status); } setIsLoading(false); });
    }
    const handleShow = async () => {
        const Ordered = <div className='stepLabel'>Ordered</div>
        const Processed = <div className='stepLabel'>Collected</div>
        const Submitted = <div className='stepLabel'>Submitted</div>
        const Rejected = <div className='stepLabel'>Rejected</div>
        const SampleCollected = <div className='stepLabel'>Sample Collected</div>
        const SampleDepartmentReceived = <div className='stepLabel'>Sample Department Received</div>
        const PReport = <div className='stepLabel'>Partial Report Generated</div>
        const Report = <div className='stepLabel'>Report Generated</div>
        glblsteps = [];
        glbltransfer = [];
        if (currentStatus === "0") {
            glblsteps.push(
                {
                    status: Ordered
                },
                // {
                //     status: Processed
                // },
                {
                    status: Submitted
                },
                // {
                //     status: SampleCollected
                // },
                {
                    status: SampleDepartmentReceived
                },
                {
                    status: PReport
                },
                {
                    status: Report
                }
            );
            glbltransfer = {
                status: Ordered
            }
        }
        else if (currentStatus === "1") {
            glblsteps.push(
                {
                    status: Ordered
                },
                // {
                //     status: Processed
                // },
                {
                    status: Submitted
                },
                // {
                //     status: SampleCollected
                // },
                {
                    status: SampleDepartmentReceived
                },
                {
                    status: PReport
                },
                {
                    status: Report
                }
            );
            glbltransfer = {
                status: Processed
            }
        }
        else if (currentStatus === "2") {
            glblsteps.push(
                {
                    status: Ordered
                },
                // {
                //     status: Processed
                // },
                {
                    status: Submitted
                },
                // {
                //     status: SampleCollected
                // },
                {
                    status: SampleDepartmentReceived
                },
                {
                    status: PReport
                },
                {
                    status: Report
                }
            );
            glbltransfer = {
                status: Submitted
            }
        }
        else if (currentStatus === "3") {
            glblsteps.push(
                {
                    status: Ordered
                },
                // {
                //     status: Processed
                // },
                {
                    status: Submitted
                },
                // {
                //     status: SampleCollected
                // },
                {
                    status: SampleDepartmentReceived
                },
                {
                    status: PReport
                },
                {
                    status: Report
                }
            );
            glbltransfer = {
                status: Report
            }
        }
        else if (currentStatus === "4") {
            glblsteps.push(
                {
                    status: Ordered
                },
                // {
                //     status: Processed
                // },
                {
                    status: Submitted
                },
                // {
                //     status: SampleCollected
                // },
                {
                    status: SampleDepartmentReceived
                },
                {
                    status: PReport
                },
                {
                    status: Report
                }
            );
            glbltransfer = {
                status: SampleCollected
            }
        }
        else if (currentStatus === "5") {
            glblsteps.push(
                {
                    status: Ordered
                },
                // {
                //     status: Processed
                // },
                {
                    status: Submitted
                },
                // {
                //     status: SampleCollected
                // },
                {
                    status: SampleDepartmentReceived
                },
                {
                    status: PReport
                },
                {
                    status: Report
                }
            );
            glbltransfer = {
                status: SampleDepartmentReceived
            }
        }
        if (currentStatus === "6") {
            glblsteps.push(
                {
                    status: Ordered
                },
                // {
                //     status: Processed
                // },
                {
                    status: Submitted
                },
                {
                    status: SampleCollected
                },
                {
                    status: Rejected
                }
            );
            glbltransfer = {
                status: Rejected
            }
        }
        else if (currentStatus === "10") {
            glblsteps.push(
                {
                    status: Ordered
                },
                // {
                //     status: Processed
                // },
                {
                    status: Submitted
                },
                // {
                //     status: SampleCollected
                // },
                {
                    status: SampleDepartmentReceived
                },
                {
                    status: PReport
                },
                {
                    status: Report
                }
            );
            glbltransfer = {
                status: PReport
            }
        }
        setSteps(glblsteps);
        setTransfer(glbltransfer);
    }
    const getStepPosition = (transferStatus) => {
        return steps.findIndex(({ status }) => status === transferStatus);
    };
    const SendReport = async (flag, id) => {
        setIsLoading(true);
        let url = FrontDesk_URL + 'labreport/GetBasicPatientDetails/' + flag + '/' + visitingCode + '/' + id
        await axios.get(url).then((response) => {
            setIsLoading(false);
            const data = response.data;
            if (data.Success) {
                if (data.Data == null) { SweetAlert.fire("No record found"); }
                else { setModalData(data.Data); }
            }
            else {
                if (data.Data != null) { SweetAlert.fire("Error is : " + data.Data); }
                else { SweetAlert.fire("Some error occured.Please ask support team to check logs"); }
            }
        }).catch(function (error) { if (error.response) { AxiosError(error.response.status); } setIsLoading(false); });
    }
    const modalHandler = () => { setSaveOTP(""); setMobNo(""); setEditMobileNumber(false); setSendOTP(false); setModal(!modal); };
    const DispatchReport = async () => {
        setIsLoading(true);
        let url = FrontDesk_URL + 'labreport/sendreportnotifications/' + modaldata[0].flag + '/' + modaldata[0].VisitingId + '/' + modaldata[0].id
        await axios.get(url).then((response) => {
            setIsLoading(false);
            const data = response.data;
            modalHandler();
            if (data.Success) {
                if (data.Data == null) { SweetAlert.fire("No record found"); }
                else if (data.Data == "1" || data.Data.toLowerCase() == "true") { SweetAlert.fire("Report sent successfully"); }
                else if (data.Data == "0" || data.Data.toLowerCase() == "false") { SweetAlert.fire("No record found"); }
                else if (data.Data == "2") { SweetAlert.fire("Notification stopped by admin from backend flag"); }
                else if (data.Data == "3") { SweetAlert.fire("Report not generated"); }
                else if (data.Data == "4") { SweetAlert.fire("You do not have sufficient credit limit to send report"); }
                else if (data.Data == "5") { SweetAlert.fire("You won't able to send report because your credit settlement is pending"); }
                else { SweetAlert.fire("No record found"); }
            }
            else {
                if (data.Data != null) { SweetAlert.fire("Error is : " + data.Data); }
                else { SweetAlert.fire("Some error occured.Please ask support team to check logs"); }
            }
        }).catch(function (error) { if (error.response) { AxiosError(error.response.status); } setIsLoading(false); });
    }
    const backHandler = (event) => { event.preventDefault(); if (localStorage.getItem('mode') === "saleregister") { history.push({ pathname: `${process.env.PUBLIC_URL}/saleregister`, search: `` }); } else { history.push({ pathname: `${process.env.PUBLIC_URL}/lab-report/teststatus`, search: `` }); } }
    const ReportResignOff = async () => {
        setIsButtonLoading(true);
        let url = CallCentre_URL + 'labreport/reportresignoff/' + localStorage.getItem('vId') + '/' + visitingCode + '/' + localStorage.getItem('LoggedInUser')
        await axios.get(url).then((response) => {
            setIsButtonLoading(false);
            const data = response.data;
            if (data.Success) { SweetAlert.fire("Report resign-off successfully"); BindGrid(); }
            else { SweetAlert.fire("Error is: " + data.Data); }
        }).catch(function (error) { if (error.response) { AxiosError(error.response.status); } setIsButtonLoading(false); });
    }
    const EditMobileNo = async () => {
        setEditMobileNumber(true);
    }
    const SendOTP = async () => {
        if (editmobno.current.value == "" || editmobno.current.value == undefined || editmobno.current.value == null) { SweetAlert.fire("Mobile no cannot be blank"); return false; }
        if (editmobno.current.value == modaldata[0].MobileNo) { SweetAlert.fire("Edited mobile no is same as previous mobile no. So no need to verify and click to send report notifications directly"); return false; }
        if (editmobno.current.value.length < 10 || editmobno.current.value.length > 10) { SweetAlert.fire("Invalid mobile no. Mobile number should be of 10 characters"); return false; }
        setIsButtonLoading(true);
        let url = FrontDesk_URL + 'labreport/sendshortotp/' + editmobno.current.value
        await axios.get(url).then((response) => {
            const data = response.data;
            setIsButtonLoading(false);
            if (data.Success) {
                setMobNo(editmobno.current.value);
                SweetAlert.fire({ title: 'OTP sent', icon: 'success', text: "We have send an otp to your entered mobile no" });
                setSendOTP(true);
                setEditMobileNumber(false);
                setSaveOTP(data.Data);
            }
            else {
                setSaveOTP("");
                setSendOTP(false);
                setMobNo("");
                SweetAlert.fire({ title: 'Unable to sent otp', icon: 'error', text: data.Data });
                return false;
            }
        }).catch(function (error) {
            setIsButtonLoading(false);
            if (error.response) {
                AxiosError(error.response.status);
            }
        });
    }
    const VerifyOTP = async () => {
        if (verifyotp.current.value == "" || verifyotp.current.value == undefined || verifyotp.current.value == null) { SweetAlert.fire("OTP cannot be blank"); return false; }
        if (verifyotp.current.value != saveotp && saveotp != "") { SweetAlert.fire("Invalid OTP."); return false; }
        if (mobno == "" || mobno == undefined || mobno == null || mobno == "0") { SweetAlert.fire("Mobile no is mandatory.Please refresh and try again"); return false; }
        if (modaldata[0].VisitingId == "" || modaldata[0].VisitingId == undefined || modaldata[0].VisitingId == null || modaldata[0].VisitingId == "0") { SweetAlert.fire("Visit no is mandatory.Please refresh and try again"); return false; }
        setIsButtonLoading(true);
        let url = FrontDesk_URL + 'labreport/verifyshortotp/' + mobno + '/' + verifyotp.current.value + '/' + modaldata[0].VisitingId
        await axios.get(url).then((response) => {
            const data = response.data;
            setIsButtonLoading(false);
            if (data.Success) {
                SweetAlert.fire({
                    title: 'Success',
                    text: "OTP verified successfully",
                    icon: 'success',
                    showCancelButton: false,
                    confirmButtonText: 'Ok',
                    reverseButtons: true
                }).then((result) => {
                    if (result.value) {
                        setSaveOTP("");
                        setSendOTP(false);
                        setEditMobileNumber(false);
                        setMobNo("");
                        modalHandler();
                        SendReport(modaldata[0].flag, modaldata[0].id);
                    } else {
                        setSaveOTP("");
                        setSendOTP(false);
                        setEditMobileNumber(false);
                        setMobNo("");
                        modalHandler();
                        SendReport(modaldata[0].flag, modaldata[0].id);
                    }
                })
            }
            else {
                if (data.Data == "-1998") { SweetAlert.fire({ title: 'Empty values', icon: 'error', text: "Values cannot be empty.Please refresh and try again" }); }
                else if (data.Data == "-1999") { SweetAlert.fire({ title: 'Invalid otp', icon: 'error', text: "OTP not matched" }); }
                else if (data.Data == "-2000") { SweetAlert.fire({ title: 'DB error', icon: 'error', text: "Check db logs" }); }
                else if (data.Data == "-2001") { SweetAlert.fire({ title: 'Network Error', icon: 'error', text: "Some network error occured.Check server logs" }); }
                else { SweetAlert.fire({ title: 'API runtime exception', icon: 'error', text: data.Data }); }
                return false;
            }
        }).catch(function (error) {
            setIsButtonLoading(false);
            if (error.response) {
                AxiosError(error.response.status);
            }
        });
    }
    const resendOTP = async () => {
        if (mobno == "" || mobno == undefined || mobno == null) { SweetAlert.fire("Mobile no cannot be blank"); return false; }
        if (mobno.length < 10 || mobno.length > 10) { SweetAlert.fire("Invalid mobile no. Mobile number should be of 10 characters"); return false; }
        setIsButtonLoading(true);
        let url = FrontDesk_URL + 'labreport/sendshortotp/' + mobno
        await axios.get(url).then((response) => {
            const data = response.data;
            setIsButtonLoading(false);
            if (data.Success) {
                SweetAlert.fire({ title: 'OTP sent', icon: 'success', text: "We have resend an otp to your entered mobile no" });
                setSaveOTP(data.Data);
            }
            else {
                setSaveOTP("");
                SweetAlert.fire({ title: 'Unable to sent otp', icon: 'error', text: data.Data });
                return false;
            }
        }).catch(function (error) {
            setIsButtonLoading(false);
            if (error.response) {
                AxiosError(error.response.status);
            }
        });
        //setMinutes(2);
        setSeconds(30);
    }
    const AxiosError = async (ErrorCode) => {
        if (ErrorCode === 400) { SweetAlert.fire({ title: "Error 400", text: "Bad request", icon: "error" }) }
        else if (ErrorCode === 401) { SweetAlert.fire({ title: "Error 401", text: "Unauthorized", icon: "error" }) }
        else if (ErrorCode === 403) { SweetAlert.fire({ title: "Error 403", text: "Forbidden", icon: "error" }) }
        else if (ErrorCode === 404) { SweetAlert.fire({ title: "Error 404", text: "Page not found", icon: "error" }) }
        else if (ErrorCode === 500) { SweetAlert.fire({ title: "Error 500", text: "Internal Server Error", icon: "error" }) }
        else if (ErrorCode === 503) { SweetAlert.fire({ title: "Error 503", text: "Service Unavailable", icon: "error" }) }
    }
    const APIError = async (ErrorCode, ErrorMsg) => {
        if (ErrorCode === "-1") { SweetAlert.fire({ title: "Data unavailable", text: "No record found", icon: "error" }) }
        else if (ErrorCode === "-2") { SweetAlert.fire({ title: "API Server Error", text: ErrorMsg, icon: "error" }); }
        else if (ErrorCode === "-3") { SweetAlert.fire({ title: "Runtime Exception", text: "Some error occured! Please try after sometime.", icon: "error" }) }
        else if (ErrorCode === "-4") { SweetAlert.fire({ title: "Wrong Credentials", text: "Please enter correct credentials", icon: "error" }) }
        else if (ErrorCode === "-5") { SweetAlert.fire({ title: "Oops", text: "Data could not be saved, Please try again later", icon: "error" }) }
        else if (ErrorCode === "-6") { SweetAlert.fire({ title: "Failed", text: "Check DB Logs", icon: "error" }) }
        else if (ErrorCode === "-7") { SweetAlert.fire({ title: "Duplicate", text: "Template already exists", icon: "error" }) }
        else if (ErrorCode === "-8") { SweetAlert.fire({ title: "Data unavailable", text: "No record found to import", icon: "error" }) }
        else if (ErrorCode === "-9") { SweetAlert.fire({ title: "LDAP authentication failed", text: ErrorMsg, icon: "error" }) }
        else { SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime", icon: "error" }) }
    }
    return (
        <React.Fragment>
            <Breadcrumb title="Test Status" parent="Lab Report" />
            <Container fluid>
                {/* <Row>
                    <Col md={12}>
                        <div className='progresWrapper'>
                            <ProgressBar
                                percent={100 * ((getStepPosition(transfer.status) + 1) / (steps.length - 1)) - 1}
                                filledBackground="linear-gradient(to right, #41ad49, #41ad49)"
                            >
                                {steps.map((step, index, arr) => {
                                    return (
                                        <Step key={index} transition="scale">
                                            {({ accomplished }) => (
                                                <div
                                                    style={{
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                        borderRadius: "50%",
                                                        width: 20,
                                                        height: 20,
                                                        color: "black",
                                                        backgroundColor: accomplished ? "#339933" : "#D9D9D9"
                                                    }}
                                                >
                                                    {step.status}
                                                </div>
                                            )}
                                        </Step>
                                    );
                                })}
                            </ProgressBar>
                        </div>
                    </Col>
                </Row> */}
                {pathologyTableData.length > 0 || pendingPathologyData.length > 0 ?
                    <Row>
                        <Col md={12}>
                            <Card>
                                <Card.Header className='pb-0'>
                                    <Card.Title>Pathology</Card.Title>
                                </Card.Header>
                                <Card.Body>
                                    <Form>
                                        <Form.Row className='justify-content-center'>
                                            {isButtonLoading ? <LoadingButton variantName='primary' cssName="" /> :
                                                <Button variant='primary' className="mr-2" onClick={(e) => ReportResignOff()}>Sync with LIS</Button>
                                            }
                                        </Form.Row>
                                        {pathologyTableData.length > 0 ?
                                            <>
                                                <Form.Row>
                                                    <Col md={12}><b>Confirmed Investigations</b></Col>
                                                    <Col md={12}>
                                                        {
                                                            isLoading ?
                                                                <div className='text-center'> <Spinner animation="border" size='sm' /></div> :
                                                                <>
                                                                    <DataTables keyField='id' tableData={pathologyTableData} columns={pathColumns} pagination={paginationFactory(paginationOptions)} />
                                                                </>
                                                        }
                                                    </Col>
                                                </Form.Row>
                                                {
                                                    allowPrint === "True" || allowPrint === "1" ?
                                                        <Form.Row className='justify-content-center'>
                                                            {/* {isButtonLoading ? <LoadingButton variantName='primary' cssName="" /> :
                                                                <Button variant='primary' className="mr-2" onClick={(e) => ReportResignOff()}>Sync with LIS</Button>
                                                            } */}
                                                            {
                                                                localStorage.PartnerTypeId === "3" || localStorage.PartnerTypeId === "4" ?
                                                                    <>
                                                                        <Button variant='primary' className="mr-2" onClick={(e) => openReport(pathlabReportUrlWithH)}>Download With Header Report</Button>
                                                                        <Button variant='secondary' className="mr-2" onClick={(e) => openReport(pathlabReportUrl)}>Download Without Header Report</Button>
                                                                        {/* {isButtonLoading ? <LoadingButton variantName='primary' cssName="" /> :
                                                                            <Button variant='primary' className="mr-2" onClick={(e) => ReportResignOff()}>Sync with LIS</Button>
                                                                        } */}
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <Form.Row className='justify-content-center'>
                                                                            <Button variant='primary' className="mr-2" onClick={(e) => openReport(pathlabReportUrlWithH)}>Download With Header Report</Button>
                                                                            <Button variant='secondary' className="mr-2" onClick={(e) => openReport(pathlabReportUrl)}>Download Without Header Report</Button>
                                                                            {/* {isButtonLoading ? <LoadingButton variantName='primary' cssName="" /> :
                                                                                <Button variant='primary' className="mr-2" onClick={(e) => ReportResignOff()}>Sync with LIS</Button>
                                                                            } */}
                                                                            <Button variant='primary' className="mr-2" onClick={() => SendReport("1", "0")}>Send Report</Button>
                                                                        </Form.Row>
                                                                    </>
                                                            }
                                                        </Form.Row>
                                                        :
                                                        allowPrint === "False" || allowPrint === "0" ?
                                                            <Form.Row className='justify-content-center'>
                                                                <Button variant='primary' className="mr-2">You do not have sufficient credit limit to download report</Button>
                                                            </Form.Row>
                                                            :
                                                            allowPrint === "2" ?
                                                                <Form.Row className='justify-content-center'>
                                                                    <Button variant='primary' className="mr-2">You won't able to view report because your discount settlement is pending</Button>
                                                                </Form.Row> :
                                                                <Form.Row className='justify-content-center'>
                                                                    <Button variant='primary' className="mr-2">You won't able to view report because your credit settlement is pending</Button>
                                                                </Form.Row>
                                                }
                                            </>
                                            : null
                                        }
                                        {pendingPathologyData.length > 0 ?
                                            <Form.Row>
                                                <Col md={12}><b>Pending Investigations</b></Col>
                                                <Col md={12}>
                                                    {
                                                        isLoading ?
                                                            <div className='text-center'> <Spinner animation="border" size='sm' /></div> :
                                                            <>
                                                                <DataTables keyField='id' tableData={pendingPathologyData} columns={pathColumns} pagination={paginationFactory(paginationOptions)} />
                                                            </>
                                                    }
                                                </Col>
                                            </Form.Row>
                                            : null
                                        }
                                    </Form>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    : null}
                {radiologyTableData.length > 0 ?
                    <Row>
                        <Col md={12}>
                            <Card>
                                <Card.Header className='pb-0'>
                                    <Card.Title>Radiology</Card.Title>
                                </Card.Header>
                                <Card.Body>
                                    {
                                        isLoading ?
                                            <div className='text-center'> <Spinner animation="border" size='sm' /></div> :
                                            <>
                                                <DataTables keyField='id' tableData={radiologyTableData} columns={rdColumns} pagination={paginationFactory(paginationOptions)} />
                                            </>
                                    }
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    : null
                }
                <Row>
                    <Col md={12}>
                        <Card>
                            <Card.Body>
                                <Form>
                                    <Form.Row className='justify-content-center'>
                                        <Button variant='secondary' className="mr-2" onClick={backHandler} >Back</Button>
                                    </Form.Row>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
            {
                modal &&
                <Modal isOpen={modal} toggle={modalHandler} centered={true} size="lg">
                    <ModalHeader toggle={modalHandler}>Notification Details</ModalHeader>
                    <ModalBody>
                        {
                            modaldata.length > 0 ?
                                <>
                                    <Row>
                                        <Col md={12}>
                                            {
                                                modaldata[0].EmailId == null || modaldata[0].EmailId == "" || modaldata[0].EmailId == undefined ? null :
                                                    <p>Email will sent on : <b>{modaldata[0].EmailId}</b></p>
                                            }
                                            {
                                                modaldata[0].MobileNo == null || modaldata[0].MobileNo == "" || modaldata[0].MobileNo == undefined ? null :
                                                    <p>Whatsapp will sent on : <b>{modaldata[0].MobileNo} (if opted in by patient)</b></p>
                                            }
                                            {
                                                modaldata[0].MobileNo == null || modaldata[0].MobileNo == "" || modaldata[0].MobileNo == undefined ? null :
                                                    <p>Sms will sent on : <b>{modaldata[0].MobileNo}</b></p>
                                            }
                                            <Form.Row className='justify-content-center'>
                                                {
                                                    isLoading ?
                                                        <div className='text-center'> <Spinner animation="border" size='sm' /></div> :
                                                        <>
                                                            <Button variant='primary' className="mr-2" onClick={EditMobileNo}>Edit Mobile Number</Button>
                                                            <Button variant='secondary' className="mr-2" onClick={DispatchReport}>Send Report Notifications</Button>
                                                        </>
                                                }
                                            </Form.Row>
                                        </Col>
                                    </Row>
                                    {
                                        editmobilenumber == true ?
                                            <Row className='mt-3'>
                                                <Col md={8}>
                                                    <Form.Group>
                                                        <Form.Control type="text" name="editmobno" defaultValue={modaldata[0].MobileNo} ref={editmobno} />
                                                    </Form.Group>
                                                </Col>
                                                <Col md={4}>
                                                    <Button variant='primary' className="mr-2" onClick={SendOTP}>Send OTP</Button>
                                                </Col>
                                            </Row>
                                            :
                                            null
                                    }
                                    {
                                        sendotp == true ?
                                            <Row className='mt-3'>
                                                <Col md={6}>
                                                    <Form.Group>
                                                        <Form.Control type="text" name="verotp" placeholder='Enter OTP' ref={verifyotp} />
                                                    </Form.Group>
                                                </Col>
                                                <Col md="auto">
                                                    <Button variant='primary' className="mr-2" onClick={VerifyOTP}>Verify OTP</Button>
                                                    {seconds > 0 ? (
                                                        <p className="mr-2" style={{ color: 'red' }}>
                                                            Time Remaining: {seconds < 10 ? `0${seconds}` : seconds}
                                                        </p>
                                                    ) :
                                                        <Button variant='secondary' className="mr-2" disabled={seconds > 0} onClick={resendOTP}>Resend OTP</Button>
                                                    }
                                                </Col>
                                            </Row>
                                            :
                                            null
                                    }
                                </> :
                                null
                        }
                    </ModalBody>
                </Modal>
            }
        </React.Fragment>
    )
}

export default ReportOverview