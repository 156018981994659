import React, { useEffect, useState, useRef } from 'react';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import axios from 'axios';
import SweetAlert from 'sweetalert2';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Spinner } from 'react-bootstrap';
import ToolTip from '../commonComponent/ToolTip';
import { FrontDesk_URL } from '../../constant';
import DataTables from '../commonComponent/DataTable/DataTables';
import NoRecordFounds from '../commonComponent/DataTable/NoRecordFounds';
import Breadcrumb from '../../components/common/breadcrumb';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import LoadingButton from '../commonComponent/LoadingButton';

var glblRecords = [];
var glbRecords = [];
var glbTestRecords = [];
var dates = "";
const RefundRequest = () => {
    const [tableData, setTableData] = useState([]);
    const [crntPageNO, setCrntPageNO] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [testsModal, setTestsModal] = useState();
    const [popupTableData, setPopupTableData] = useState([]);
    const [visitsTableData, setVisitsTableData] = useState([]);
    const [patientName, setPatientName] = useState();
    const [patientAge, setPatientAge] = useState();
    const [visitNo, setVisitNo] = useState();
    const openModal = () => { setTestsModal(!testsModal); };
    const [displayTestsList, setDisplayTestsList] = useState(false);
    const [modal, setModal] = useState();
    const toggleModal = () => { setModal(!modal) }
    const [statevalue, setStateValue] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [refundAmount, setRefundAmount] = useState(0.00);
    const [enableRefund, setEnableRefund] = useState(false);
    const [users, setUsers] = useState([]);
    const [emailid, setEmailId] = useState("");
    const [centretypeid, setCentreTypeId] = useState(localStorage.CentreTypeId);
    const [centreid, setCentreId] = useState(localStorage.CentreSNo);
    const [panelid, setPanelId] = useState("0");

    useEffect(() => {
        const current = new Date();
        const date = current.getDate() < "10" ? "0" + current.getDate() : current.getDate();
        const month = (current.getMonth() + 1) < "10" ? "0" + (current.getMonth() + 1) : (current.getMonth() + 1);
        const year = current.getFullYear();
        dates = `${year}-${month}-${date}`;
        formik.values.fromdate = `${year}-${month}-${date}`;
        formik.values.todate = `${year}-${month}-${date}`;
        setStateValue(true);
        // BindGrid("", "");
    }, [])
    let refundreason = useRef();
    let refundauthorizers = useRef();
    const paginationOptions = {
        sizePerPage: 10, showTotal: false, firstPageText: 'First', prePageText: 'Back', nextPageText: 'Next', lastPageText: 'Last',
        onPageChange: (page, sizePerPage) => { setCrntPageNO(((page - 1) * sizePerPage)); }
    };
    const idFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <span>{crntPageNO + rowIndex + 1}</span>
            </div>
        </React.Fragment>
    }
    const actionFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <ToolTip title="Check Visits" placement="top" onClick={() => GetVisits(rowData)} linkId="View" faName="fa fa-eye mr-2"></ToolTip>
            </div>
        </React.Fragment>
    }
    const popupActionFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <ToolTip title="Check Tests" placement="top" onClick={() => GetTestsList(rowData.VisitingId, rowData.VisitingCode, rowData.OriginalInvoiceNo)} linkId="TestList" faName="fa fa-eye mr-2"></ToolTip>
            </div>
        </React.Fragment>
    }
    const testsActionFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            {
                <div>
                    {
                        rowData.InvestigationType === "2" ?
                            rowData.InvestigationStatus === "3" ? <span>Report Generated</span> :
                                <>
                                    {
                                        (rowData.IsRefunded == "1") ? <span>Approved</span> :
                                            (rowData.IsRefunded == "2") ? <span>Pending</span> :
                                                (rowData.IsRefunded == "3") ?
                                                    <>
                                                        <span>Rejected</span>
                                                        &nbsp;&nbsp;
                                                        <input className="checkbox_animated" type="checkbox" onClick={(e) => { handleEditCheckbox(e, rowData); }} />
                                                    </>
                                                    :
                                                    <input className="checkbox_animated" type="checkbox" onClick={(e) => { handleEditCheckbox(e, rowData); }} />
                                    }
                                </>
                            :
                            <>
                                {
                                    rowData.InvestigationStatus === "5" ? <span>Sample Department Received</span> :
                                        rowData.InvestigationStatus === "3" ? <span>Report Generated</span> :
                                            <>
                                                {
                                                    (rowData.IsRefunded == "1") ? <span>Approved</span> :
                                                        (rowData.IsRefunded == "2") ? <span>Pending</span> :
                                                            (rowData.IsRefunded == "3") ?
                                                                <>
                                                                    <span>Rejected</span>
                                                                    &nbsp;&nbsp;
                                                                    <input className="checkbox_animated" type="checkbox" onClick={(e) => { handleEditCheckbox(e, rowData); }} />
                                                                </>
                                                                :
                                                                <input className="checkbox_animated" type="checkbox" onClick={(e) => { handleEditCheckbox(e, rowData); }} />
                                                }
                                            </>
                                }
                            </>
                    }
                </div>
            }
        </React.Fragment>
    }
    // const testsActionFormatter = (cellData, rowData, rowIndex, extraData) => {
    //     return <React.Fragment>
    //         {
    //             <div>
    //                 {
    //                     rowData.InvestigationType === "2" ?
    //                         rowData.InvestigationStatus === "3" ? <span>Report Generated</span> :
    //                             <>
    //                                 {
    //                                     (rowData.IsRefunded == "1") ? <span>Approved</span> :
    //                                         (rowData.IsRefunded == "2") ? <span>Pending</span> :
    //                                             (rowData.IsRefunded == "3") ?
    //                                                 <>
    //                                                     <span>Rejected</span>
    //                                                     &nbsp;&nbsp;
    //                                                     <input className="checkbox_animated" type="checkbox" onClick={(e) => { handleEditCheckbox(e, rowData); }} />
    //                                                 </>
    //                                                 :
    //                                                 <input className="checkbox_animated" type="checkbox" onClick={(e) => { handleEditCheckbox(e, rowData); }} />
    //                                 }
    //                             </>
    //                         :
    //                         <>
    //                             {
    //                                 rowData.InvestigationStatus === "5" ? <span>Sample Department Received</span> :
    //                                     rowData.InvestigationStatus === "3" ? <span>Report Generated</span> :
    //                                         rowData.InvestigationStatus === "6" && rowData.IsRefunded == "4" ?
    //                                             <>
    //                                                 <span>Rejected</span>
    //                                                 &nbsp;&nbsp;
    //                                                 <input className="checkbox_animated" type="checkbox" onClick={(e) => { handleEditCheckbox(e, rowData); }} />
    //                                             </> :
    //                                             rowData.InvestigationStatus === "6" && rowData.IsRefunded === "1" ?
    //                                                 <span>Approved</span> :
    //                                                 rowData.InvestigationStatus === "6" ?
    //                                                     <span>Rejected Pending</span>
    //                                                     :
    //                                                     <>
    //                                                         {
    //                                                             (rowData.IsRefunded == "1") ? <span>Approved</span> :
    //                                                                 (rowData.IsRefunded == "2") ? <span>Pending</span> :
    //                                                                     (rowData.IsRefunded == "3") ?
    //                                                                         <>
    //                                                                             <span>Rejected</span>
    //                                                                             &nbsp;&nbsp;
    //                                                                             <input className="checkbox_animated" type="checkbox" onClick={(e) => { handleEditCheckbox(e, rowData); }} />
    //                                                                         </>
    //                                                                         :
    //                                                                         <input className="checkbox_animated" type="checkbox" onClick={(e) => { handleEditCheckbox(e, rowData); }} />
    //                                                         }
    //                                                     </>
    //                             }
    //                         </>
    //                 }
    //             </div>
    //         }
    //     </React.Fragment>
    // }
    const columns = [
        { dataField: '#', text: '#', editable: false, formatter: idFormatter, headerStyle: (colum, colIndex) => { return { width: '5%' }; } },
        { dataField: 'PatientName', text: 'Name', editable: false },
        { dataField: 'Age', text: 'Age', editable: false },
        { dataField: 'Gender', text: 'Gender', editable: false },
        { dataField: 'MobileNo', text: 'Mobile', editable: false },
        { dataField: 'VisitType', text: 'VisitType', editable: false },
        { dataField: 'actions', text: 'Actions', formatter: actionFormatter, editable: false, headerStyle: (colum, colIndex) => { return { width: '7%' }; } }
    ];
    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
    const formik = useFormik({
        initialValues: { mobileno: '', visitno: '', fromdate: '', todate: '' },
        validationSchema: Yup.object({
            mobileno: Yup.string().max(10, 'Mobile no must be 10 numbers').min(10, 'Mobile no must be 10 numbers').matches(phoneRegExp, 'Mobile no is not valid')
        }),
        onSubmit: values => { handleSearch(values); },
    });
    const handleSearch = async (values) => {
        if (values.mobileno === "" && values.visitno === "") { SweetAlert.fire("Atleast one of the above fields is mandatory for searching"); return false; }
        else { BindGrid(values.mobileno, values.visitno, values.fromdate, values.todate); }
        setDisplayTestsList(false);
    }
    const BindGrid = async (mobileno, visitno) => {
        setTableData([]);
        setIsLoading(true);
        let url = FrontDesk_URL + 'accounts/searchpatientsaleforrefund/' + (mobileno === "" ? "0" : mobileno) + '/' + (visitno === "" ? "0" : visitno) + '/' + localStorage.CentreSNo
        await axios.get(url).then((response) => {
            const data = response.data;
            glbRecords = [];
            setIsLoading(false);
            if (data.Success) {
                if (data.Data != null) {
                    glbRecords = data.Data;
                    setTableData(glbRecords);
                    setDisplayTestsList(false);
                }
            }
            else { APIError(data.Data, data.ErrorList.length === 0 ? "" : data.ErrorList[0].errorMsg); setIsLoading(false); }
        }).catch(function (error) { if (error.response) { AxiosError(error.response.status); } setIsLoading(false); });
    }
    const popupColumns = [
        { dataField: '#', text: '#', editable: false, formatter: idFormatter, headerStyle: (colum, colIndex) => { return { width: '7%' }; } },
        { dataField: 'OrderDate', text: 'Order Date', editable: false },
        { dataField: 'VisitingCode', text: 'Visit Id', editable: false },
        { dataField: 'actions', text: 'Actions', formatter: popupActionFormatter, editable: false, headerStyle: (colum, colIndex) => { return { width: '15%' }; } }
    ];
    const GetVisits = async (rwData) => {
        setDisplayTestsList(false);
        setPatientName(rwData.PatientName);
        setPatientAge(rwData.Age);
        if (rwData.PatientVisits.length > 0) {
            if (rwData.PatientVisits.length > 1) { setPopupTableData(rwData.PatientVisits); openModal(); }
            else { GetTestsList(rwData.PatientVisits[0].VisitingId, rwData.PatientVisits[0].VisitingCode, rwData.PatientVisits[0].OriginalInvoiceNo); }
        }
        GetUsersForRefundRequest();
    }
    const testsColumns = [
        { dataField: '#', text: '#', editable: false, formatter: idFormatter, headerStyle: (colum, colIndex) => { return { width: '7%' }; } },
        { dataField: 'ItemCode', text: 'Test Code', editable: false },
        { dataField: 'Investigation', text: 'Test Name', editable: false },
        { dataField: 'ItemMRP', text: 'MRP', editable: false },
        { dataField: 'Discount', text: 'Discount', editable: false },
        { dataField: 'ItemRate', text: 'Rate', editable: false },
        { dataField: 'actions', text: 'Actions', editable: false, formatter: testsActionFormatter, headerStyle: (colum, colIndex) => { return { width: '15%' }; } }
    ];
    const GetTestsList = async (id, visitcode, orgInvoiceNo) => {
        setTestsModal(false);
        setVisitNo(visitcode);
        setIsLoading(true);
        if (orgInvoiceNo !== "") { setEnableRefund(false); } else { setEnableRefund(true); }
        let url = FrontDesk_URL + 'accounts/getinvestigationsforrefunds/' + id + "/" + localStorage.CentreSNo;
        await axios.get(url).then((response) => {
            const data = response.data;
            if (data.Success) {
                if (data.Data == null || data.Data == [] || data.Data.length == 0) {
                    SweetAlert.fire("You cannot refund this item because either it is not rejected or it's report is generated");
                }
                else{
                    setPanelId(data.Data[0].PanelId)
                    glbTestRecords = data.Data;
                    setVisitsTableData(glbTestRecords);
                    setDisplayTestsList(true);
                    refundreason.current.value = data.Data[0].RefundReason;
                }
                setIsLoading(false);
            }
            else { APIError(data.Data, data.ErrorList.length === 0 ? "" : data.ErrorList[0].errorMsg); setIsLoading(false); }
        }).catch(function (error) { if (error.response) { AxiosError(error.response.status); } setIsLoading(false); });
    }
    const handleEditCheckbox = (e, rawData) => {
        const checked = e.target.checked;
        setRefundAmount(0.00);
        for (var i in glbTestRecords) {
            if (glbTestRecords[i].Id === rawData.Id) {
                glbTestRecords[i].IsRefund = "False";
                if (checked) { glbTestRecords[i].IsRefund = "True"; };
            }
        }
        var refndAmount = 0;
        for (var x in glbTestRecords) {
            if (glbTestRecords[x].IsRefund == "True") { refndAmount = parseFloat(refndAmount) + parseFloat(glbTestRecords[x].ItemRate); }
        }
        setRefundAmount(refndAmount);
    };
    const ProcessRefunds = async () => {
        if (refundreason.current.value === "") {
            SweetAlert.fire("Refund reason is mandatory");
            return false;
        }
        if (centretypeid == "1" && panelid == "0") {
            if ((refundauthorizers.current.value === "0" || refundauthorizers.current.value === "")) {
                SweetAlert.fire("Refund authorizer is mandatory");
                return false;
            }
        }
        if (parseFloat(refundAmount) > 0) {
            var arrayRefunds = [];
            var refndAmount = 0;
            var vId = 0;
            for (var i in glbTestRecords) {
                var isRefund = "0"; if (glbTestRecords[i].IsRefund === "True") { vId = glbTestRecords[i].VisitId; isRefund = "1"; refndAmount = parseFloat(refndAmount) + parseFloat(glbTestRecords[i].ItemRate); }
                if (isRefund === "1") { arrayRefunds.push({ Id: glbTestRecords[i].Id, IsRefund: (centretypeid == "1" && panelid == "0") ? isRefund : "1" }); }
            }
            setIsSaving(true);
            if ((parseFloat(refndAmount) == parseFloat(refundAmount)) && parseFloat(refndAmount) > 0) {
                const requestOptions = {
                    VisitId: vId,
                    RefundBy: localStorage.LoggedInUser,
                    RefundAmount: parseFloat(refundAmount).toFixed(2),
                    RefundInfo: arrayRefunds,
                    RefundToUser: (centretypeid == "1" && panelid == "0") ? refundauthorizers.current.value : "0",
                    RefundUserName: (centretypeid == "1" && panelid == "0") ? (refundauthorizers.current.options[refundauthorizers.current.selectedIndex].text).split("[")[0] : "",
                    RefundReason: refundreason.current.value,
                    EmailId: emailid,
                    VisitingCode: visitNo,
                    RefundByName: localStorage.UserName,
                    PatientName: patientName == null || patientName == undefined ? "" : patientName
                };
                let url = FrontDesk_URL + 'accounts/refundrequested'
                await axios.post(url, requestOptions).then((response) => {
                    const data = response.data;
                    if (data.Success) {
                        if (centretypeid == "1" && panelid == "0") { SweetAlert.fire({ title: "Success", text: "Refund requested successfully", icon: "success" }); }
                        else { SweetAlert.fire({ title: "Success", text: "Refund approved successfully", icon: "success" }); }
                        setIsSaving(false);
                        setRefundAmount(0.00);
                        setDisplayTestsList(false);
                    }
                    else {
                        if (data.Data == "-2000") { setIsSaving(false); SweetAlert.fire("You cannot make a refund because outstanding amount is pending.Please settle pending amount and initiate refund."); }
                        else if (data.Data == "-2") { setIsSaving(false); SweetAlert.fire("Refund authorizer is not selected. Please refresh page or logout and login again."); }
                        else { SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime", icon: "error" }); setIsSaving(false); }
                    }
                }).catch(function (error) { setIsSaving(false); });
            }
        } else { SweetAlert.fire({ title: "Validation  Failed", text: "Refund amount must be greater than ZERO", icon: "error" }); }
    }
    const GetUsersForRefundRequest = async () => {
        try {
            let url = FrontDesk_URL + 'accounts/getrefundauthorizers/' + localStorage.CentreSNo
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) {
                    glblRecords = data.Data;
                    setUsers(data.Data.map((d) => (<option key={d.Id} value={d.Id}>{d.Value}</option>)))
                }
                else {
                    glblRecords = [];
                    setUsers([]);
                }
            }).catch(function (error) {
                if (error.response) {
                    glblRecords = [];
                    setUsers([]); AxiosError(error.response.status);
                }
            });
        }
        catch (error) {
            glblRecords = [];
            setUsers([]); SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" });
        }
    }
    const GetAuthorizerEmailId = async (e) => {
        if (e.target.value == "0") { setEmailId(""); }
        else {
            if (glblRecords.length < 1) { setEmailId(""); }
            else {
                var iterateloop = 0;
                var emId = "";
                for (var key in glblRecords) {
                    if (glblRecords[key].Id === e.target.value && iterateloop === 0) {
                        iterateloop = 1;
                        emId = glblRecords[key].EmailId
                    }
                }
                setEmailId(emId);
            }
        }
    }
    const AxiosError = async (ErrorCode) => {
        if (ErrorCode === 400) { SweetAlert.fire({ title: "Error 400", text: "Bad request", icon: "error" }) }
        else if (ErrorCode === 401) { SweetAlert.fire({ title: "Error 401", text: "Unauthorized", icon: "error" }) }
        else if (ErrorCode === 403) { SweetAlert.fire({ title: "Error 403", text: "Forbidden", icon: "error" }) }
        else if (ErrorCode === 404) { SweetAlert.fire({ title: "Error 404", text: "Page not found", icon: "error" }) }
        else if (ErrorCode === 500) { SweetAlert.fire({ title: "Error 500", text: "Internal Server Error", icon: "error" }) }
        else if (ErrorCode === 503) { SweetAlert.fire({ title: "Error 503", text: "Service Unavailable", icon: "error" }) }
    }
    const APIError = async (ErrorCode, ErrorMsg) => {
        if (ErrorCode === "-1") { SweetAlert.fire({ title: "Data unavailable", text: "No record found", icon: "error" }) }
        else if (ErrorCode === "-2") { SweetAlert.fire({ title: "API Server Error", text: ErrorMsg, icon: "error" }); }
        else if (ErrorCode === "-3") { SweetAlert.fire({ title: "Runtime Exception", text: "Some error occured! Please try after sometime.", icon: "error" }) }
        else if (ErrorCode === "-4") { SweetAlert.fire({ title: "Wrong Credentials", text: "Please enter correct credentials", icon: "error" }) }
        else if (ErrorCode === "-5") { SweetAlert.fire({ title: "Oops", text: "Data could not be saved, Please try again later", icon: "error" }) }
        else if (ErrorCode === "-6") { SweetAlert.fire({ title: "Failed", text: "Check DB Logs", icon: "error" }) }
        else if (ErrorCode === "-7") { SweetAlert.fire({ title: "Duplicate", text: "Template already exists", icon: "error" }) }
        else if (ErrorCode === "-8") { SweetAlert.fire({ title: "Data unavailable", text: "No record found to import", icon: "error" }) }
        else if (ErrorCode === "-9") { SweetAlert.fire({ title: "LDAP authentication failed", text: ErrorMsg, icon: "error" }) }
        else { SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime", icon: "error" }) }
    }
    return (
        <React.Fragment>
            <Breadcrumb title="Refund Request" parent="Registration" />
            <Container fluid>
                <Row>
                    <Col md={12}>
                        <Card>
                            <Card.Body>
                                <Form onSubmit={formik.handleSubmit}>
                                    <Form.Row className='justify-content-center'>
                                        <Col md={4} lg={3}>
                                            <Form.Group>
                                                <Form.Label>Mobile No</Form.Label>
                                                <Form.Control type='text' name="mobileno" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.mobileno} />
                                                {formik.touched.mobileno && formik.errors.mobileno ? (<div className='text-required'>{formik.errors.mobileno}</div>) : null}
                                            </Form.Group>
                                        </Col>
                                        <Col md={4} lg={3}>
                                            <Form.Group>
                                                <Form.Label>Visit Id</Form.Label>
                                                <Form.Control type='text' name="visitno" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.visitno} />
                                                {formik.touched.visitno && formik.errors.visitno ? (<div className='text-required'>{formik.errors.visitno}</div>) : null}
                                            </Form.Group>
                                        </Col>
                                        <Col md={4} lg={3} className='mt-4'>
                                            <Button variant='primary' type='submit'>Search</Button>
                                        </Col>
                                    </Form.Row>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <Card>
                            <Card.Header className='pb-0'>
                                <Card.Title>Patient List</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                {
                                    isLoading ?
                                        <div className='text-center'> <Spinner animation="border" size='sm' /></div> :
                                        <>
                                            <DataTables keyField='id' tableData={tableData} columns={columns} />
                                        </>
                                }
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                {displayTestsList ?
                    <>
                        <Row>
                            <Col md={12}>
                                <Card>
                                    <Card.Header className='pb-0'>
                                        <Card.Title>Patient Item List</Card.Title>
                                    </Card.Header>
                                    <Card.Body>
                                        <div className='row align-items-center'>
                                            <div className='col-md-10 order-2 order-md-1'>
                                                <p><b>Name:</b>{patientName}, <b>Age:</b> {patientAge} Years, <b>Visit Id:</b> {visitNo} {enableRefund ? null : <b className='text-red'>Refunded Order</b>}</p>
                                            </div>
                                            {/* <div className='col-md-2 text-right order-1 order-md-2'>
                                            {isReportAvailable ? <Button variant="secondary" onClick={handleTestStatus}>Open Report</Button> : null}
                                        </div> */}
                                        </div>
                                        {
                                            isLoading ?
                                                <div className='text-center'> <Spinner animation="border" size='sm' /></div> :
                                                <>
                                                    <DataTables keyField='id' tableData={visitsTableData} columns={testsColumns} pagination={paginationFactory(paginationOptions)} />
                                                </>
                                        }
                                        {
                                            centretypeid == "1" && panelid == "0" ?
                                                <Form.Row className='justify-content-left mt-4'>
                                                    <Col md={3} lg={3}>
                                                        <Form.Group>
                                                            <Form.Label>Refund Authorizers <span className='text-red'>*</span></Form.Label>
                                                            <Form.Control as="select" name="refundauthorizers" ref={refundauthorizers} onChange={(e) => GetAuthorizerEmailId(e)}>
                                                                <option key="0" value="0">---Select---</option>
                                                                {users}
                                                            </Form.Control>
                                                        </Form.Group>
                                                    </Col>
                                                </Form.Row>
                                                :
                                                null
                                        }
                                        <Form.Row className='justify-content-left mt-4'>
                                            <Col md={6} lg={6}>
                                                <Form.Group>
                                                    <Form.Label>Refund reason <span className='text-red'>*</span></Form.Label>
                                                    <Form.Control as="textarea" rows={3} name="refundreason" ref={refundreason} />
                                                </Form.Group>
                                            </Col>
                                        </Form.Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        {enableRefund ?
                            <Row>
                                <Col md={12} className='text-center'>
                                    {isSaving ?
                                        <LoadingButton variantName='primary' cssName="mr-2" /> :
                                        <>
                                            {/* <Button variant='secondary' type='submit' className="mr-2" onClick={ProcessRefunds}>Reset</Button> */}
                                            <Button variant='primary' type='submit' className="mr-2" onClick={ProcessRefunds}>Refund Request {parseFloat(refundAmount).toFixed(2)}</Button>
                                        </>
                                    }
                                </Col>
                            </Row>
                            : null
                        }
                    </>
                    : <Row></Row>
                }
                {testsModal &&
                    <Modal isOpen={testsModal} toggle={openModal} centered={true} size="lg">
                        <ModalHeader toggle={openModal}>Test List</ModalHeader>
                        <ModalBody>
                            <Row>
                                <Col md={12}>
                                    <Card>
                                        <Card.Body>
                                            <DataTables keyField='PlabId' tableData={popupTableData} columns={popupColumns} />
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </ModalBody>
                    </Modal>
                }
            </Container>
        </React.Fragment >
    )
}

export default RefundRequest