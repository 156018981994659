import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Button } from "react-bootstrap";
import Swal from 'sweetalert2';
import classes from './CartSummary.module.css';



const OrderSummary = (props) => {
    const [itemdetails, setItemDetails] = useState([]);
    useEffect(() => {
        showallitemDetails();
    }, [])
    const showallitemDetails = () => {
        var arr1 = [...new Map(props.ItemDetails.map((m) => [m.Key, m])).values()];
        var arr2 = props.ItemDetails;
        var arr3 = [];
        var ItemName = "";
        var ActualPrice = 0;
        var DiscountPrice = 0;
        var Count = 0;
        for (var key in arr1) {
            ItemName = "";
            ActualPrice = 0;
            DiscountPrice = 0;
            Count = 0;
            for (var keyy in arr2) {
                if ((arr1[key].ItemMasterId === arr2[keyy].ItemMasterId) && (arr1[key].IsPackage === arr2[keyy].IsPackage)) {
                    ItemName = arr1[key].ItemMasterName;
                    ActualPrice += parseFloat(arr2[keyy].ActualPrice);
                    DiscountPrice += parseFloat(arr2[keyy].DiscountPrice);
                    Count = parseFloat(Count) + 1;
                }
            }
            arr3.push({
                ItemMasterName: ItemName,
                ItemActualPrice: ActualPrice,
                ItemDiscountPrice: DiscountPrice,
                ItemCount: Count
            });
        }
        const unique = [...new Map(arr3.map((m) => [m.ItemMasterName, m])).values()];
        arr3 = unique;
        setItemDetails(arr3);
    }
    const CallFullName = (ItemName) => {
        Swal.fire(ItemName);
    }
    return (
        <>
            <Card>
                <Card.Header className={classes.bgTransparent}>
                    <p className='mb-0 fw-bold ft18'>Order Summary Details</p>
                </Card.Header>
                <Card.Body>
                    <Row>
                        {
                            itemdetails.length > 0 ?
                                itemdetails.map((d) =>
                                    <>
                                        <Col xs={8} className="textDark mb-2">
                                            <b>{d.ItemMasterName.length > 20 ? <>{d.ItemMasterName.slice(0, 20)} <span style={{ cursor: 'pointer' }} onClick={(e) => CallFullName(d.ItemMasterName)}>...</span></> : d.ItemMasterName}</b>  (x{d.ItemCount})
                                        </Col>
                                        <Col xs={4} className="text-right mb-2">
                                            <span className={classes.salePrice}>&#8377;{d.ItemDiscountPrice}</span> <span className={classes.comparePrice}>{d.ItemDiscountPrice === d.ItemActualPrice ? null : <>&#8377;{d.ItemActualPrice}</>}</span>
                                        </Col>
                                    </>
                                )
                                :
                                null
                        }
                    </Row>
                </Card.Body>
            </Card>
        </>
    )
}

export default OrderSummary